import { useStore } from 'vuex'
import { useAuth } from '@/use/auth'

export const useProfile = () => {
  const store = useStore()
  const auth = useAuth()

  const getProfile = (refresh) => {
    return new Promise((resolve, reject) => {
      const profile = store.getters['Profile/current']
      if (!refresh && profile) {
        resolve(profile)
      } else {
        auth.isAuthenticated()
          .then(() => {
            store.dispatch('Profile/get')
              .then((t) => {
                resolve(t)
              })
              .catch((e) => {
                reject(e)
              })
          }).catch((e) => {
            reject(e)
          })
      }
    })
  }

  const canCreateSubUsers = () => {
    const current = store.getters['Profile/current']
    if (current && current.masteruser) {
      return false
    }
    if (current && current?.groups) {
      const freeForAllGroupId = 1
      return current.groups.filter(group => group.id !== freeForAllGroupId).length !== 0
    } else {
      return false
    }
  }

  const deleteUser = (password) => {
    return store.dispatch('Profile/deleteUser', password)
  }

  const confirmUser = (token) => store.dispatch('Confirm/get', token)

  const updatePasswordByToken = ({ token, password }) => store.dispatch('Profile/updatePasswordByToken', { token, password })

  const updatePassword = ({ oldPassword, newPassword }) => store.dispatch('Profile/updatePassword', { oldPassword, newPassword })

  const addEmail = (email) => {
    return store.dispatch('Profile/postEmail', { email })
  }
  const removeEmail = (id) => {
    return store.dispatch('Profile/deleteEmail', id)
  }
  const update = (profile) => {
    return new Promise((resolve, reject) => {
      store.dispatch('Profile/put', profile).then(() => {
        getProfile(true)
          .then(p => resolve(p))
          .catch(e => reject(e))
      }).catch(e => reject(e))
    })
  }

  const isProcessingAsync = () => {
    return new Promise((resolve, reject) => {
      (function getStatus () {
        const statusGet = store.getters['Profile/statusGet']
        if (statusGet === 'loading') {
          setTimeout(getStatus, 500)
        } else {
          resolve()
        }
      })()
    })
  }

  const isProcessing = () => {
    const statusGet = store.getters['Profile/statusGet']
    const status = store.getters['Profile/status']
    return status === 'loading' || statusGet === 'loading'
  }

  const create = (profile) => {
    return new Promise((resolve, reject) => {
      const expectedProperties = ['usertype_id', 'username', 'email', 'flag_contact_ok', 'flag_accept_terms', 'flag_accept_gdpr']
      const missingProperties = expectedProperties.filter((property) => !Object.prototype.hasOwnProperty.call(profile, property))
      if (missingProperties.length > 0) {
        const error = 'User profile input did not contain ' + missingProperties.join(', ')
        reject(error)
      } else {
        store.dispatch('CreateUser/post', profile)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }

  const current = () => store.getters['Profile/current']
  const statusGet = () => { return store.getters['Profile/statusGet'] }
  const status = () => store.getters['Profile/status']

  return {
    current,
    status,
    statusGet,
    create,
    update,
    updatePassword,
    updatePasswordByToken,
    isProcessing,
    isProcessingAsync,
    addEmail,
    removeEmail,
    getProfile,
    deleteUser,
    confirmUser,
    canCreateSubUsers
  }
}
