import /* webpackPreload: true */ { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'

// eslint-disable-next-line one-var
const MainLayout = () => import(/* webpackChunkName: "MainLayout", webpackMode: "lazy", webpackPreload: true */ '@/layouts/MainLayout.vue'),
  Home = () => import(/* webpackChunkName: "Home", webpackMode: "lazy", webpackPreload: true */ '@/views/Home.vue'),
  Cases = () => import(/* webpackChunkName: "Cases", webpackMode: "lazy", webpackPreload: true */ '@/views/Cases.vue'),
  Case = () => import(/* webpackChunkName: "Case", webpackMode: "lazy", webpackPreload: true */ '@/views/Case.vue'),
  Datasets = () => import(/* webpackChunkName: "Datasets", webpackMode: "lazy", webpackPreload: true */ '@/views/Datasets.vue'),
  Dataset = () => import(/* webpackChunkName: "Dataset", webpackMode: "lazy", webpackPreload: true */ '@/views/Dataset.vue'),
  Map = () => import(/* webpackChunkName: "Map", webpackMode: "lazy", webpackPreload: true */ '@/views/Map.vue'),
  Apps = () => import(/* webpackChunkName: "Apps", webpackMode: "lazy", webpackPreload: true */ '@/views/Apps.vue'),
  User = () => import(/* webpackChunkName: "User", webpackMode: "lazy", webpackPreload: true */ '@/views/User.vue'),
  Labs = () => import(/* webpackChunkName: "Labs", webpackMode: "lazy", webpackPreload: true */ '@/views/Labs.vue'),
  Lab = () => import(/* webpackChunkName: "Lab", webpackMode: "lazy", webpackPreload: true */ '@/views/Lab.vue'),
  NewsOverview = () => import(/* webpackChunkName: "NewsOverview", webpackMode: "lazy", webpackPreload: true */ '@/views/NewsOverview.vue'),
  News = () => import(/* webpackChunkName: "News", webpackMode: "lazy", webpackPreload: true */ '@/views/News.vue'),
  About = () => import(/* webpackChunkName: "About", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/About.vue'),
  Cookies = () => import(/* webpackChunkName: "Cookies", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/Cookies.vue'),
  Privacy = () => import(/* webpackChunkName: "Privacy", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/Privacy.vue'),
  Terms = () => import(/* webpackChunkName: "Terms", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/Terms.vue'),
  PageBrowserIncompatible = () => import(/* webpackChunkName: "PageBrowserIncompatible", webpackMode: "lazy", webpackPreload: true */ '@/views/PageBrowserIncompatible.vue'),
  PageNotFound = () => import(/* webpackChunkName: "404", webpackMode: "lazy", webpackPreload: true */ '@/views/PageNotFound.vue'),
  Monitoring = () => import(/* webpackChunkName: "Monitoring", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/Monitoring.vue'),
  Help = () => import(/* webpackChunkName: "Help", webpackMode: "lazy", webpackPreload: true */ '@/views/infoPages/Help.vue')

const mainLayoutRoutes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home
    },
    meta: {
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/cases',
    alias: '/case',
    name: 'Cases',
    component: Cases,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Cases',
      description: 'Hvordan kan geografiske data skabe værdi i din forretning? Få viden og inspiration til hvordan geodata kan understøtte din kommunikation, give dig overblik og skabe bedre beslutningsgrundlag, som kan ses på bundlinjen. Frie offentlige kort og data skaber nye muligheder og værdi for både offentlige og private, læs hvordan andre har skabt bedre løsninger med brug af geodata.'
    }
  },
  {
    path: '/cases/:id',
    alias: '/case/:id',
    name: 'Case',
    component: Case,
    meta: {
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/data',
    alias: '/dataset',
    name: 'Datasets',
    component: Datasets,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Data',
      description: 'Har du brug for et baggrundskort, højdekurver, bygninger eller veje. Find informationer om data, se data på kort, hent webservices og download data. BEMÆRK du kan logge ind med din eksisterende Kortforsyningsbruger.'
    }
  },
  {
    path: '/data/:id',
    alias: '/dataset/:id',
    name: 'Dataset',
    component: Dataset,
    meta: {
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/apps',
    alias: '/apps',
    name: 'Apps',
    component: Apps,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Web Apps',
      description: 'Har du brug for at finde skråfoto på din adresse, gå på opdagelse i nye og historiske kort eller skal du bruge oplysninger om fikspunkter til opmåling og kortlægning. Få overblik over alle vores webapplikationer her.'
    }
  },
  {
    path: '/labs',
    alias: '/lab',
    name: 'Labs',
    component: Labs,
    meta: {
      domain: 'labs.dataforsyningen.dk',
      title: 'Labs',
      description: 'Applikationer under udvikling, demoer, proof of concepts og mere i samme dur.'
    }
  },
  {
    path: '/labs/:id',
    alias: '/lab/:id',
    name: 'Lab',
    component: Lab,
    meta: {
      domain: 'labs.dataforsyningen.dk'
    }
  },
  {
    path: '/news',
    alias: '/news',
    name: 'NewsOverview',
    component: NewsOverview,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'News',
      description: 'Nyheder på Dataforsyningen.'
    }
  },
  {
    path: '/news/:id',
    alias: '/news/:id',
    name: 'News',
    component: News,
    meta: {
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      textColor: 'black',
      domain: 'dataforsyningen.dk',
      title: 'User',
      description: 'Din personlige bruger profil på Dataforsyningen.'
    }
  },
  {
    path: '/cookies',
    alias: '/CookiePolitik',
    name: 'Cookies',
    component: Cookies,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Cookiepolitik',
      description: 'Dataforsyningens cookiepolitik.'
    }
  },
  {
    path: '/about',
    alias: '/OmDataforsyningen',
    name: 'About',
    component: About,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Om Dataforsyningen',
      description: 'Hvad er Dataforsyningens formål og hvad kan du bruge den til?'
    }
  },
  {
    path: '/terms',
    alias: '/Vilkaar',
    name: 'Terms',
    component: Terms,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Vilkår',
      description: 'Vilkår for brug af Dataforsyningen og de data der stilles til rådighed.'
    }
  },
  {
    path: '/privacy',
    alias: '/Privatlivspolitik',
    name: 'Privacy',
    component: Privacy,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Privatlivspolitik',
      description: 'Dataforsyningens privatlivspolitik.'
    }
  },
  {
    path: '/monitoring',
    alias: '/drift',
    name: 'Monitoring',
    component: Monitoring,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Drift',
      description: 'Driftstatus for Dataforsyningens webservices og downloads.'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Help',
      description: 'Oftet stillede spørgsmål og vejledninger til Dataforsyningen og dens services.'
    }
  },
  {
    path: '/was',
    beforeEnter () {
      location.href = 'https://www.was.digst.dk/dataforsyningen-dk'
    },
    name: 'Webtilgaengelighed',
    meta: {
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/PageBrowserIncompatible',
    name: 'PageBrowserIncompatible',
    component: PageBrowserIncompatible,
    meta: {
      textColor: 'black',
      domain: 'dataforsyningen.dk'
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      textColor: 'black',
      domain: 'dataforsyningen.dk'
    }
  }
]

const noLayoutRoutes = [
  {
    path: '/map',
    name: 'Maps',
    component: Map,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Kort',
      description: 'Find og se adresser, stednavne og områder på kort.'

    }
  },
  {
    path: '/map/:id',
    name: 'Map',
    component: Map,
    meta: {
      domain: 'dataforsyningen.dk',
      title: 'Kort',
      description: 'Find og se adresser, stednavne og områder på kort.'
    }
  }
]

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: mainLayoutRoutes
  },
  ...noLayoutRoutes
]

// scrollBehavior = () => { setTimeout(function () { window.scrollTo(0, 0) }, 200) },
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    let ret = { left: 0, top: 0, behavior: 'smooth' }
    nextTick(() => {
      if (to.hash) {
        ret = { el: to.hash.substring(1), behavior: 'smooth' }
        document?.getElementById(ret.el)?.scrollIntoView(true)
        return { el: to.hash, behavior: 'smooth' }
      } else if (savedPosition) {
        ret = savedPosition
      }
      window.scrollTo(ret)
      return ret
    })
  }
})
router.beforeEach((to, from, next) => {
// Sensible default values if nothing is defined
  to.meta.description = to.meta?.description || 'Dataforsyningen giver dig direkte adgang til frie offentlige geografiske data via kortvisning, webservices, web applikationer, API\'er og download. Du vil også finde viden om, hvordan data skaber nye muligheder og værdi for både offentlige og private. I KDS Labs kan du afprøve prototyper under udvikling, med mulighed for at give feedback til udviklerne bag prototypen.'
  to.meta.title = to.meta?.title || 'Dataforsyningen' // Set meta
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
  document.querySelector('meta[property="og\\:description"]').setAttribute('content', to.meta.description)
  // Set title and og:title meta
  document.title = to.meta.title
  document.querySelector('meta[property="og\\:title"]').setAttribute('content', to.meta.title)
  next()
})

// redirect to subdomain
/*
router.beforeEach((to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to.meta, 'domain') && (to.meta.domain === 'labs.dataforsyningen.dk')) {
    window.location.host = to.meta.domain
    return
  }
  next()
})
*/

/*
// TODO: use lazyloading and dynamic module loading per route/component
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line one-var
  const name = to.name,
    // nameLower = name.toLowerCase(),
    stores = to.meta.stores ?? []
  for (let i = stores.length - 1; i > -1; --i) {
    // const path = '@/store/' + nameLower + '/' + stores[i]
    import('@/store/' + stores[i]).then((module) => {
      if (!store.state[module]) {
        store.registerModule(Object.keys(module)[0], module)
      }
      next()
      return module
    }, (err) => {
      console.error('Error', err)
    })
  }
})
*/

/*
// TODO: Use navigation guard for login
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
*/

export default router
