export const Dialog = {
  namespaced: true,
  state: () => ({
    current: '',
    parameters: {},
    open: false
  }),
  mutations: {
    set (state, { dialog, parameters }) {
      state.current = dialog ?? ''
      if (parameters) {
        Object.entries(parameters).forEach(([key, value]) => {
          state.parameters[key] = value
        })
      }
      state.open = !!dialog
    },
    reset (state) {
      state.current = ''
      state.parameters = {}
      state.open = false
    }
  },
  actions: {
    show: async ({ commit }, command) => {
      commit('set', command)
    },
    reset: async ({ commit }) => {
      commit('reset')
    }
  },

  getters: {
    current: (state) => state.current,
    parameters: (state) => state.parameters,
    open: (state) => state.open
  }
}
