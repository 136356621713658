import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseDatasets } from '@/store/shared/ParseData'
import { productsSearch } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const query = () => {
    // eslint-disable-next-line multiline-ternary
    return (process.env.VUE_APP_SHOW_UNPUBLISHED === 'true') ? {
      size: 10000,
      sort: [
        { 'product_title.keyword': { order: 'desc' } }
      ]
    } : {
      size: 10000,
      sort: [
        { 'product_title.keyword': { order: 'desc' } }
      ],
      query: {
        match: {
          o_published: true
        }
      }
    }
  },
  crud = new ElasticSearch(query, productsSearch, parseDatasets),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

mutations.sort = (state, desc) => {
  state.data.sort((a, b) => {
    // eslint-disable-next-line one-var
    const nameA = a.title.toUpperCase(),
      nameB = b.title.toUpperCase()
    if (nameA < nameB) {
      return desc ? -1 : 1
    } else if (nameA > nameB) {
      return desc ? 1 : -1
    }
    return 0
  })
}

actions.sort = ({ commit }, desc) => {
  commit('sort', desc)
}

getters.datasets = (state, getters, rootState) => {
  // Can't use .filter or .find on the proxy array for some reason, so using forEach instead.
  const loginFilters = rootState.DatasetsFilters.loginFilters
  const userGroups = rootState.Profile.data?.groups || []
  const loginFiltersAdjusted = []
  loginFilters.forEach(filter => {
    let allowed = false
    userGroups.forEach(e => {
      if (e.title === filter.title) {
        allowed = true
      }
    })
    if (!allowed) {
      loginFiltersAdjusted.push(filter)
    }
  })

  const filteredDatasets = []
  state.data.forEach(dataset => {
    let visible = true
    dataset.tags.forEach(tag => {
      if (tag.type === 'tag') {
        loginFiltersAdjusted.forEach(e => {
          if (e.id === tag.id) {
            visible = false
          }
        })
      }
    })
    if (visible) {
      filteredDatasets.push(dataset)
    }
  })
  return filteredDatasets
}

getters.datasetsUnfiltered = (state) => {
  return state.data
}

export const Datasets = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
