import { tagFilterType, webserviceFilterType, downloadFilterType, filterGroupPriorityList, downloadFormatId, loginRequiredTagPimcoreId } from '@/Constants'

/*
** Query format
** filter=[FilterGroupId];[FilterGroupType]-[FilterId];[FilterId],[FilterGroupId]...
*/
const routerName = 'Datasets'
const groupSplit = ','
const typeSplit = ';'
const subSplit = '-'
const filterSplit = ';'

const orderByPriority = (filterGroups, filterPriorityList) => {
  const orderedList = []
  filterPriorityList.forEach(el => {
    const filterGroup = filterGroups.find(fg => {
      return fg.name === el
    })
    if (filterGroup) {
      orderedList.push(filterGroup)
    }
  })
  filterGroups.forEach(fg => {
    const match = orderedList.find(el => {
      return el.name === fg.name && el.id === fg.id
    })
    if (!match) {
      orderedList.push(fg)
    }
  })
  return orderedList
}

const parseQuery = (query) => {
  const filterGroups = []
  if (!query) {
    return filterGroups
  }
  const splitByGroup = query.split(groupSplit)
  for (let i = 0; i < splitByGroup.length; i++) {
    const splitBySub = splitByGroup[i].split(subSplit)
    const splitByType = splitBySub[0].split(typeSplit)
    const splitByFilter = splitBySub[1].split(filterSplit)
    const filters = []
    for (let j = 0; j < splitByFilter.length; j++) {
      filters.push(Number(decodeURI(splitByFilter[j])))
    }
    filterGroups.push({
      id: Number(decodeURI(splitByType[0])),
      type: decodeURI(splitByType[1]),
      filters
    })
  }
  return filterGroups
}

const getQuery = (filterGroups) => {
  let urlString = ''
  for (let i = 0; i < filterGroups.length; i++) {
    const filterGroup = filterGroups[i]
    if (i > 0) {
      urlString += groupSplit
    }
    urlString += encodeURI(filterGroup.id) + typeSplit + encodeURI(filterGroup.type) + subSplit
    for (let j = 0; j < filterGroup.filters.length; j++) {
      const filter = filterGroup.filters[j]
      if (j > 0) {
        urlString += filterSplit
      }
      urlString += encodeURI(filter.id)
    }
  }
  return urlString
}

const isActive = (activeFilterGroups, filter) => {
  const filterGroup = activeFilterGroups.find(filterGroup => {
    return filterGroup.id === filter.groupId && filterGroup.type === filter.type
  })
  if (!filterGroup) {
    return false
  }
  const isActive = filterGroup.filters.find(id => {
    return id === filter.id
  })
  return !!isActive
}

export const DatasetsFilters = {
  namespaced: true,
  state: () => ({
    filters: [],
    filterGroups: [],
    loginFilters: [],
    view: 'gallery',
    search: ''
  }),

  getters: {
    view (state) {
      return state.view
    },
    search (state) {
      return state.search
    },
    activeFilterGroups (state) {
      const activeFilterGroups = []
      state.filterGroups.forEach(filterGroup => {
        const activeFilters = []
        filterGroup.filters.forEach(filter => {
          if (filter.active) {
            activeFilters.push(filter)
          }
        })
        if (activeFilters[0]) {
          activeFilterGroups.push({
            name: filterGroup.name,
            id: filterGroup.id,
            type: filterGroup.type,
            filters: activeFilters
          })
        }
      })
      return activeFilterGroups
    }
  },

  mutations: {
    clear (state) {
      state.data = []
    },
    setView (state, view) {
      state.view = view
    },
    toggleFilter (state, filter) {
      const fil = state.filters.find(e => {
        return filter.id === e.id && filter.type === e.type
      })
      if (fil) {
        fil.active = !fil.active
      }
    },
    createLoginFilters (state, tags) {
      // Create filter for hidding data that requires login
      const loginFilter = []
      tags.forEach(e => {
        if (e.parentId === loginRequiredTagPimcoreId) {
          loginFilter.push(e)
        }
      })
      state.loginFilters = loginFilter
    },
    createFilters (state, data) {
      const datasets = data.datasets
      const tags = data.tags
      const webserviceFormats = data.attributes[2].list
      const downloadFormats = data.attributes[1].list
      const query = parseQuery(data.query)
      const filters = []
      const filterGroups = []

      // find tags, webservices and downloads
      const tagIds = []
      const webserviceFormatIds = []
      const downloadFormatIds = []
      datasets.forEach(dataset => {
        dataset.tags.forEach(tag => {
          tagIds.push(tag.id)
        })
        dataset.services.forEach(service => {
          webserviceFormatIds.push(service.typeId)
        })
        dataset.downloads.forEach(download => {
          download.formatIds.forEach(formatId => {
            downloadFormatIds.push(formatId)
          })
        })
      })

      const uniqueTagIds = Array.from(new Set(tagIds))
      const uniqueWebserviceFormatIds = Array.from(new Set(webserviceFormatIds))
      const uniqueDownloadFormatIds = Array.from(new Set(downloadFormatIds))

      // create filters based on tags
      tags.forEach(tag => {
        if (uniqueTagIds.includes(tag.id)) {
          const filter = {
            name: tag.title,
            id: tag.id,
            type: tagFilterType,
            groupId: tag.parentId
          }
          filter.active = isActive(query, filter)
          filters.push(filter)
          // add group if it doesn't already exist
          const group = filterGroups.find(group => {
            return group.id === tag.parentId
          })
          if (group) {
            group.filters.push(filter)
          } else {
            const groupTag = tags.find(e => {
              return e.id === tag.parentId && e.type === tag.type
            })
            if (groupTag && groupTag.parentId === 0 && filterGroupPriorityList.includes(groupTag.title)) {
              filterGroups.push({
                name: groupTag.title,
                id: groupTag.id,
                type: tagFilterType,
                filters: [filter]
              })
            }
          }
        }
      })

      // create filters based on webservice
      const webserviceFormatGroup = {
        name: 'Webservice',
        id: 0,
        type: webserviceFilterType,
        filters: []
      }
      if (uniqueWebserviceFormatIds[0]) {
        filterGroups.push(webserviceFormatGroup)
      }
      webserviceFormats.forEach(webserviceFormat => {
        if (uniqueWebserviceFormatIds.includes(webserviceFormat.id)) {
          const filter = {
            name: webserviceFormat.title,
            id: webserviceFormat.id,
            type: webserviceFilterType,
            groupId: 0
          }
          filter.active = isActive(query, filter)
          filters.push(filter)
          webserviceFormatGroup.filters.push(filter)
        }
      })

      // create filters based on download
      const downloadFormatGroup = {
        name: 'Download',
        id: 0,
        type: downloadFilterType,
        filters: []
      }
      if (uniqueDownloadFormatIds[0]) {
        filterGroups.push(downloadFormatGroup)
      }
      downloadFormats.forEach(downloadFormat => {
        if (uniqueDownloadFormatIds.includes(downloadFormat.id)) {
          const filter = {
            name: downloadFormat.title,
            id: downloadFormat.id,
            type: downloadFilterType,
            groupId: 0
          }
          filter.active = isActive(query, filter)
          filters.push(filter)
          downloadFormatGroup.filters.push(filter)
        }
      })

      // custom add ftp as download filter
      const ftpFilter = {
        name: 'FTP',
        id: downloadFormatId.ftp,
        type: downloadFilterType,
        groupId: 0
      }
      ftpFilter.active = isActive(query, ftpFilter)
      filters.push(ftpFilter)
      downloadFormatGroup.filters.push(ftpFilter)

      // add to state
      state.filters = filters
      state.filterGroups = orderByPriority(filterGroups, filterGroupPriorityList)
    },
    setSearch (state, text) {
      state.search = text
    }
  },
  actions: {
    createFilters ({ commit }, data) {
      commit('createFilters', data)
    },
    createLoginFilters ({ commit }, data) {
      commit('createLoginFilters', data)
    },
    toggleFilter ({ commit, dispatch }, data) {
      commit('toggleFilter', data.item)
      dispatch('updateUrl', data.route)
    },
    createView ({ commit }, view) {
      if (view) {
        commit('setView', view)
      }
    },
    setView ({ commit, dispatch }, data) {
      commit('setView', data.view)
      dispatch('updateUrl', data.route)
    },
    createSearch ({ commit }, search) {
      if (search) {
        commit('setSearch', search)
      }
    },
    setSearch ({ commit, dispatch }, data) {
      commit('setSearch', data.text)
      dispatch('updateUrl', data.route)
    },
    updateUrl ({ getters }, route) {
      // update the URL with the new filter
      const view = getters.view
      const query = getQuery(getters.activeFilterGroups)
      const search = getters.search
      // check if location has changed
      if (route.name === routerName) {
        history.replaceState(
          {},
          null,
          route.path + '?filter=' + query + '&view=' + view + '&search=' + search
        )
      }
    },
    clear ({ commit }) {
      commit('clear')
    }
  }
}
