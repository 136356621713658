import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'

const url = baseUrl + 'subuser/'
const urlGet = baseUrl + 'subusers/'
const urlUsername = baseUrl + 'username/'
const urlUser = baseUrl + 'user/'
const urlEmail = baseUrl + 'email/'

// initial state
const state = {
  users: '',
  status: '',
  statusGet: '',
  errorMessage: '',
  position: '',
  frozen: false
}

// getters
const getters = {
  users (state) { return state.users },
  latestError (state) { return state.errorMessage }
}

// actions
const actions = {
  get ({ commit, dispatch }) {
    return new Promise(function (resolve, reject) {
      commit('requestGet')
      axios.get(urlGet)
        .then(function (resp) {
          const users = resp.data.map(function (e) {
            return {
              ...e,
              expire: e.expiration_date ? new Date(e.expiration_date) : null,
              usertypeId: e.usertype_id
            }
          })
          commit('successGet', users)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  create ({ commit, dispatch }, { username, name, email, expirationDate }) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.post(url, { username, name, email, expiration_date: expirationDate })
        .then(function (resp) {
          commit('successCreate')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  delete ({ commit, dispatch }, { id, password }) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.delete(urlUser + id,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password
          }
        })
        .then(function (resp) {
          commit('successDelete')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  update ({ commit, dispatch }, { id, name, expirationDate, addresses, phonenumbers, usertypeId }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUser + id, { name, addresses, phonenumbers, usertype_id: usertypeId, expiration_date: expirationDate }, { timeout: 10000 })
        .then((resp) => {
          commit('successUpdate', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  updateUsername ({ commit, dispatch }, { id, username }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUsername + id, { username }, { timeout: 10000 })
        .then((resp) => {
          commit('successUpdate', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  addEmail ({ commit, dispatch }, { id, email }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.post(urlEmail + id, { email }, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  deleteEmail ({ commit, dispatch }, { id, emailId }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.delete(urlEmail + id,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            email_id: emailId
          },
          timeout: 10000
        })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  release ({ commit, dispatch }, user) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.put(url + 'free',
        {
          subuser_id: user
        })
        .then(function (resp) {
          commit('successPut', user.subuser_id)
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  requestGet (state) {
    state.statusGet = 'loading'
  },
  request (state) {
    state.status = 'loading'
  },
  successGet (state, users) {
    state.statusGet = 'success'
    state.users = users
  },
  successCreate (state) {
    state.status = 'success'
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  successPut (state, userId) {
    state.status = 'success'
    state.users = state.users.filter(function (e) { return e.id !== userId })
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  successUpdate (state, user) {
    state.status = 'success'
    state.users = state.users.filter(function (e) { return e.id !== user })
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  successDelete (state) {
    state.status = 'success'
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  error (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'error'
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  errorGet (state) {
    state.statusGet = 'error'
  }
}

export const SubUsers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
