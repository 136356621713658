// filter types, should match name in pimcore
export const tagFilterType = 'tag'
export const webserviceFilterType = 'serviceType'
export const downloadFilterType = 'format'
export const coordinateFilterType = 'coordinateSystem'

// filter priority list for ordering filter groups.
// Also serves as a whitelist for filters groups. Names must match exact.
export const filterGroupPriorityList = [
  'Tema',
  'Produkter',
  'Webservice',
  'Download',
  'Aktualitet',
  'Land',
  'Myndighed',
  'Adgang',
  'Vilkår'
]

// pimcore id of formats
// should we instead set things such as download icon and "show capabilities" as a parameter on the format in pimcore?
export const apiPimcoreIDs = [
  2225, // API
  1528, // TESTOAF-API
  3766, // REST
  4922 // OGCAPI
]

// download format -> format id in pimcore
// bruges til ftp/http boxes for at få farver fra pimcore.
export const downloadFormatId = {
  ftp: 3781,
  http: 3793
}

export const webserviceFormat = {
  wms: 1524,
  wmts: 1525
}

// the download types that will be displayed
export const enabledDownloads = [
  'ftp',
  'http',
  'map',
  'fme'
]

// the cutout types that does not rely on a link
export const cutouts = {
  all: 'Hele landet',
  all2: 'Samlet Dataset',
  draw: 'Tegn et område'
}

// hardcoded extra downloads when downloading Matrikelkort, Geodanmark or Hoejdemodel to also download the other two.
export const extraDownloads = [
  {
    id: 1014,
    datasetName: 'matriklen',
    title: 'Matrikelkortet'
  },
  {
    id: 926,
    datasetName: 'hoejdekurver',
    title: 'Danmarks Højdemodel - Højdekurver'
  },
  {
    id: 3563,
    datasetName: 'geodanmark',
    title: 'Danmarks Geografi - GeoDanmark'
  }
]

// id of the parent tag used for flagging products as requiring login to be visible.
export const loginRequiredTagPimcoreId = 181

// id of the country tag, used to get the country for download until downloads become their own objects in pimcore.
export const countryTagId = 99

// Do not use download url directly, instead use the function getDownloadUrl in Helperfunctions.
// download url
export const downloadUrl = 'https://api.dataforsyningen.dk/rest/fildownload/v1.0/'

export const downloadUrlTest = 'https://api.dataforsyningen.dk/rest/fildownload_test/v1.0/'

// max area allowed by FME downloads (square km).
export const maxAreaDefault = 10

// prefixes used by orderIDs to tell predefined and userdefined apart
export const predefinedPrefix = 'p'
export const userdefinedPrefix = 'b'

// Dataforsyningen API token used for search calls
export const DF_AUTH = 'da31e749c5c22811f801535ad91ba0a6'

// How often the basket should check for updates to predefined orders in miliseconds
export const basketRefreshInterval = 20000

// geopackage default to soft, everything else to hard
export const softClipFormats = [
  51
]

// download search constants
export const downloadSearchResources = 'navngivenvej,husnummer,stednavn,matrikel'
export const downloadSearchLimit = 5

// hidden product to use for the default /map page
export const defaultMapID = 4807

// hidden news to use for the /about page
export const defaultAboutID = 4976

// hidden news to use for the /cookies page
export const defaultCookiesID = 4977

// hidden news to use for the /terms page
export const defaultTermsID = 4974

// hidden news to use for the /privacy page
export const defaultPrivacyID = 4973

// hidden news to use for the /monitor page
export const defaultMonitorID = 4975

// id of labs pages that should be hidden on the /labs/ overview page. Currently used to hide the english site.
export const labBlacklist = [
  4803
]

// Sizes to rescale for mobile view
export const mobileMaxWidth = 600
export const tabletMaxWidth = 1320

// Texts that should be added to pimcore
export const supportText = 'Har du brug for hjælp? Ring til supporten, vi har åbent mandag til torsdag kl. 09:00-14:00, og fredag kl. 10:00-14:00.'
export const questionsText = 'Har du spørgsmål eller ændringsforslag til Dataforsyningen? Skriv til supporten. Vi vender tilbage til dig hurtigst muligt.'

// The id for the "CC BY 4.0" tag
export const CCBYTagId = 217
// The id for the "Myndighed" tag
export const authorityTagId = 97

// Header navigation content
export const navigationContent = [
  {
    title: 'Cases',
    icon: 'cases',
    link: '/cases'
  },
  {
    title: 'Data',
    icon: 'data',
    link: '/data'
  },
  {
    title: 'Kort',
    icon: 'map',
    link: '/map'
  },
  {
    title: 'Web apps',
    icon: 'apps',
    link: '/apps'
  }
]

// Header/user navigation content
export const userNavigationContent = [
  {
    title: 'Ret brugeroplysninger',
    icon: 'user',
    link: '/user',
    anchor: '#profile'
  },
  {
    title: 'Tilmeld nyhedsbrev og beskeder',
    icon: 'mail',
    link: '/user',
    anchor: '#subscriptions'
  },
  {
    title: 'Hent data klar til download',
    icon: 'data',
    link: '/user',
    anchor: '#data'
  },
  {
    title: 'Administrer token til webservices og API\'er',
    icon: 'token',
    link: '/user',
    anchor: '#token'
  }
]

// Password validators. Make sure these match the requirements in Brugerstyring.
export const passwordValidators = [
  {
    text: 'Små bogstaver',
    showAlways: true,
    validate: (value) => /[a-z]/.test(value)
  },
  {
    text: 'Store bogstaver',
    showAlways: true,
    validate: (value) => /[A-Z]/.test(value)
  },
  {
    text: 'Tal fra 0 til 9',
    showAlways: true,
    validate: (value) => /\d/.test(value)
  },
  {
    text: 'Minimum 10 tegn',
    showAlways: true,
    validate: (value) => value.length >= 10
  },
  {
    text: 'Specialtegn "&" kan ikke anvendes',
    showAlways: false,
    validate: (value) => (!/&/g.test(value))
  },
  {
    text: 'Adgangskoder er ens',
    showAlways: true,
    validate: (value, secondValue) => value === secondValue
  }
]

// Username validators. Make sure these match the requirements in Brugerstyring.
export const usernameValidators = [
  {
    text: 'Være minimum 3 tegn langt',
    showAlways: true,
    validate: (value) => value.length >= 3
  },
  {
    text: 'Kun indeholde A-Z, a-z og 0-9',
    showAlways: true,
    validate: (value) => /^[A-Za-z0-9]+$/.test(value)
  }
]

// Email validators. Make sure these match the requirements in Brugerstyring.
export const emailValidators = [
  {
    text: 'E-mail skal have korrekt format (fx navn@domæne.dk)',
    showAlways: true,
    validate: (value) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())
  }
]

// The default gradient used for the hero on non overview sites.
export const defaultHeroGradient =
  'linear-gradient(180deg,' +
  'rgba(0, 46, 66, 0.90) 0%,' +
  'rgba(0, 90, 128, 0.70) 15%,' +
  'rgba(0, 75, 107, 0.50) 30%,' +
  'rgba(0, 59, 87, 0.30) 50%,' +
  'rgba(0, 46, 66, 0.00) 100%),' +
  'linear-gradient(90deg,' +
  'rgba(0, 46, 66, 0.90) 0%,' +
  'rgba(0, 90, 128, 0.70) 15%,' +
  'rgba(0, 75, 107, 0.50) 30%,' +
  'rgba(0, 59, 87, 0.30) 50%,' +
  'rgba(0, 46, 66, 0.00) 100%)'
export const defaultHeroGradientMobile =
  'linear-gradient(180deg,' +
  'rgba(0, 46, 66, 0.90) 0%,' +
  'rgba(0, 90, 128, 0.70) 15%,' +
  'rgba(0, 75, 107, 0.50) 30%,' +
  'rgba(0, 59, 87, 0.30) 50%,' +
  'rgba(0, 46, 66, 0.00) 100%),' +
  'linear-gradient(0deg,' +
  'rgba(0, 46, 66, 0.90) 0%,' +
  'rgba(0, 90, 128, 0.70) 15%,' +
  'rgba(0, 75, 107, 0.50) 30%,' +
  'rgba(0, 59, 87, 0.30) 50%,' +
  'rgba(0, 46, 66, 0.00) 100%)'
export const labsHeroGradient =
  'linear-gradient(180deg,' +
  'rgba(204, 158, 0, 0.90) 0%,' +
  'rgba(204, 170, 25, 0.70) 15%,' +
  'rgba(184, 148, 10, 0.50) 30%,' +
  'rgba(153, 124, 7, 0.30) 50%,' +
  'rgba(140, 113, 0, 0.00) 100%),' +
  'linear-gradient(90deg,' +
  'rgba(204, 158, 0, 0.90) 0%,' +
  'rgba(204, 170, 25, 0.70) 15%,' +
  'rgba(184, 148, 10, 0.50) 30%,' +
  'rgba(153, 124, 7, 0.30) 50%,' +
  'rgba(140, 113, 0, 0.00) 100%)'
