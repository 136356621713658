import axios from 'axios'
import convert from 'xml-js'

export const Capability = {
  namespaced: true,
  state: () => ({
    status: '',
    data: []
  }),

  getters: {
    getData (state) {
      return state.data
    }
  },

  mutations: {
    request (state) {
      state.status = 'loading'
    },
    success (state, { data, url }) {
      // if exists remove existing and add
      const found = state.data.find((d) => { return d.id === url })
      if (found) {
        const index = state.data.indexOf(found)
        if (index > -1) {
          state.data.splice(index, 1)
        }
      }

      const parsedData = convert.xml2js(data, { compact: true, alwaysChildren: true })
      state.data.push({
        id: url,
        data: parsedData
      })
      state.status = 'success'
    },
    error (state) {
      state.status = 'error'
    },
    clear (state) {
      state.data = []
    }
  },

  actions: {
    get ({ commit }, url) {
      return new Promise((resolve, reject) => {
        commit('request')
        axios.get(url)
          .then((resp) => {
            commit('success', { data: resp.data, url })
            resolve(resp)
          })
          .catch((err) => {
            commit('error', err)
            reject(err)
          })
      })
    },
    clear ({ commit, dispatch }) {
      commit('clear')
    }
  }
}
