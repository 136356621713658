export const mGetId = (obj) => {
  return {
    size: 9999,
    // eslint-disable-next-line no-prototype-builtins
    ...((obj.hasOwnProperty('sortName'))
      ? {
          sort: [
            { [obj.sortName + '.keyword']: { order: 'desc' } }
          ]
        }
      : {}),
    query: {
      ids: {
        values: obj.ids.filter(Number)
      }
    }
  }
}

export const getAll = () => {
  return {
    size: 9999,
    query: {
      match_all: {}
    }
  }
}

export const getAllMunicipalities = () => {
  return {
    _source: ['kommunekode', 'navn'],
    size: 9999,
    query: {
      match_all: {}
    }
  }
}

export const getAllRegions = () => {
  return {
    _source: ['regionskode', 'navn'],
    size: 9999,
    query: {
      match_all: {}
    }
  }
}
