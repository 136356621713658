import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'
const url = baseUrl + 'token/'

// initial state
const state = {
  statusGet: '',
  status: '',
  tokens: [],
  errorMessage: ''
}

// getters
const getters = {
  tokens (state) { return state.tokens }
}

// actions
const actions = {
  get ({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      commit('requestGet')
      let c = 0
      axios.get(url) // + rootState.SubUsers.selectedUser)
        .then((resp) => {
          const tokens = resp.data.map((e) => {
            return {
              id: e.id,
              token: e.token,
              description: e.description ?? 'Navnløs token #' + ++c,
              expire: e.expiration_date === null ? null : new Date(e.expiration_date)
            }
          })
          commit('successGet', tokens)
          resolve(resp)
        })
        .catch((err) => {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  create ({ commit, dispatch, rootState }, description) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.post(url, { description: description ?? '' }) // + rootState.SubUsers.selectedUser)
        .then((resp) => {
          commit('successCreate', { token: resp.data, description: description ?? '', expire: null })
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  put ({ commit, dispatch, rootState }, token) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(url, token) // + rootState.SubUsers.selectedUser, token)
        .then((resp) => {
          commit('successPut', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  delete ({ commit, dispatch, rootState }, token) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.delete(url, // + rootState.SubUsers.selectedUser,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            token
          }
        })
        .then((resp) => {
          commit('successDelete', token)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  clear ({ commit }) {
    commit('clearState')
  }
}

// mutations
const mutations = {
  requestGet (state) {
    state.statusGet = 'loading'
  },
  request (state) {
    state.status = 'loading'
  },
  successGet (state, tokens) {
    state.statusGet = 'success'
    state.tokens = tokens
  },
  successCreate (state, token) {
    state.status = 'success'
    state.tokens.push(token)
    setTimeout(() => { state.status = 'idle' }, 1000)
  },
  successPut (state, token) {
    state.status = 'success'
    const index = state.tokens.findIndex((e) => { return e.token === token.token })
    state.tokens[index].description = token.description
    state.tokens[index].expire = token.expiration_date === null ? null : new Date(token.expiration_date)
    setTimeout(() => { state.status = 'idle' }, 1000)
  },
  successDelete (state, token) {
    state.status = 'success'
    state.tokens = state.tokens.filter((e) => { return e.token !== token })
  },
  error (state, err) {
    state.errorMessage = (err.response.status === 500) ? '' : err.response.data
    state.status = 'error'
    setTimeout(() => { state.status = 'idle' }, 1000)
  },
  errorGet (state) {
    state.statusGet = 'error'
  },
  clearState (state) {
    state.statusGet = ''
    state.status = ''
    state.tokens = []
    state.errorMessage = ''
  }
}

export const Tokens = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
