import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { linkboxSearch } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const query = () => {
    // eslint-disable-next-line multiline-ternary
    return (process.env.VUE_APP_SHOW_UNPUBLISHED === 'true') ? {
      size: 10000,
      sort: [
        { linkbox_priority: { order: 'desc' } }
      ]
    } : {
      size: 10000,
      sort: [
        { linkbox_priority: { order: 'desc' } }
      ],
      query: {
        match: {
          o_published: true
        }
      }
    }
  },
  parseData = (data) => {
    const state = []
    for (let i = data.hits.hits.length - 1, c = 0; i > -1; --i, ++c) {
      const curItem = data.hits.hits[i]._source
      state[c] = {
        title: curItem.linkbox_title,
        summary: curItem.linkbox_summary,
        image: curItem.linkbox_image,
        link: curItem.linkbox_link,
        linkTitle: curItem.linkbox_linktitle,
        internalLink: curItem.linkbox_internallink,
        priority: curItem.linkbox_priority,
        backgroundGradient: curItem.linkbox_gradient || ''
      }
    }
    /*
    data.hits.hits.forEach((e) => {
      const box = {
        title: e._source.linkbox_title,
        summary: e._source.linkbox_summary,
        image: e._source.linkbox_image,
        link: e._source.linkbox_link,
        linkTitle: e._source.linkbox_linktitle,
        internalLink: e._source.linkbox_internallink,
        priority: e._source.linkbox_priority
      }
      state.push(box)
    })
    return state.sort((a, b) => {
      return a.priority - b.priority
    })
    */
    return state
  },
  crud = new ElasticSearch(query, linkboxSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const LinkBox = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
