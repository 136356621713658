import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseAssets } from '@/store/shared/ParseData'
import { mGetId } from '@/store/shared/Queries'
import { assetsGet } from '@/store/shared/Paths'

const crud = new ElasticSearch(mGetId, assetsGet, parseAssets)
const state = crud.state
const getters = crud.getters
const actions = crud.actions
const mutations = crud.mutations

export const TermsAssets = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
