import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseNew } from '@/store/shared/ParseData'
import { newsSearch } from '@/store/shared/Paths'
import { mGetId } from '@/store/shared/Queries'

const parseData = (data) => {
  const state = []
  const array = data.hits ? data.hits.hits : data.docs
  array.forEach(hit => {
    state.push(parseNew(hit))
  })
  return state
}
const crud = new ElasticSearch(mGetId, newsSearch, parseData)
const state = crud.state
const getters = crud.getters
const actions = crud.actions
const mutations = crud.mutations

export const About = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
