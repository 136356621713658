import axios from 'axios'

const url = window.location.origin + '/user-api/'

// initial state
const state = {
  status: '',
  data: []
}

// getters
const getters = {
}

// actions
const actions = {
  get ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.get(url + data.type + '/confirm/' + data.token)
        .then(function (resp) {
          commit('success', data)
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  request (state) {
    state.status = 'loading'
  },
  success (state, data) {
    state.data = data
    state.status = 'success'
  },
  error (state) {
    state.status = 'error'
  }
}

export const Confirm = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
