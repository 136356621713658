import axios from 'axios'
import { predefinedPrefix, userdefinedPrefix } from '@/Constants'

const url = window.location.origin + '/brugerdownloads/'

// initial state
const state = {
  statusGet: '',
  statusPost: '',
  data: [],
  dataFME: []
}

// getters
const getters = {
  data (state) {
    return state.data.concat(state.dataFME).sort((a, b) => {
      return Number(b.createdAt) - Number(a.createdAt)
    })
  }
}

// actions
const actions = {
  get ({ commit, dispatch, rootState }) {
    return new Promise(function (resolve, reject) {
      commit('requestGet')
      axios.get(url + 'order')
        .then(function (resp) {
          commit('successGet', resp.data)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  getFME ({ commit, dispatch, rootState }) {
    return new Promise(function (resolve, reject) {
      commit('requestGet')
      axios.get(url + 'custom-order')
        .then(function (resp) {
          commit('successGetFME', resp.data)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  post ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      commit('requestPost')
      axios.post(url + 'order', data)
        .then(function (resp) {
          commit('successPost', resp.data)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorPost', err)
          reject(err)
        })
    })
  },
  postFME ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      commit('requestPost')
      axios.post(url + 'custom-order', data)
        .then(function (resp) {
          commit('successPost', resp.data)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorPost', err)
          reject(err)
        })
    })
  },
  clear ({ commit }) {
    commit('clearState')
  }
}

// mutations
const mutations = {
  requestGet (state) {
    state.statusGet = 'loading'
  },
  requestPost (state) {
    state.statusPost = 'loading'
  },
  successGet (state, data) {
    const parsed = []
    data.forEach(order => {
      const parsedOrder = {
        id: predefinedPrefix + order.orderId,
        createdAt: new Date(order.createdAt),
        expiresAt: new Date(order.expiresAt),
        formatId: order.fileFormat,
        coordId: order.epsg,
        title: order.title,
        subtitle: order.subtitle,
        ready: true,
        files: []
      }
      const path = order.filePath
      order.files.forEach(file => {
        // TODO check if file.fileExists is false and handle it.
        parsedOrder.files.push({
          path,
          filename: file.filename,
          size: file.fileSize,
          url: file.fileUrl
        })
      })
      parsed.push(parsedOrder)
    })
    state.statusGet = 'success'
    state.data = parsed
  },
  successGetFME (state, data) {
    const parsed = []
    data.forEach(order => {
      const fileFormat = order.fileFormat
      const outputEpsg = order.outputEpsg
      const format = this.state.DatasetsAttributes.data[1].list.find(a => {
        if (!a.extension) {
          return false
        }
        return a.extension[0] === fileFormat
      })
      const coord = this.state.DatasetsAttributes.data[0].list.find(a => {
        return Number(a.epsg) === outputEpsg
      })
      const parsedOrder = {
        id: userdefinedPrefix + order.orderId,
        createdAt: new Date(order.createdAt),
        expiresAt: new Date(order.expiresAt),
        formatId: format?.id || 0,
        coordId: coord?.id || 0,
        title: order.title,
        subtitle: order.subtitle,
        ready: order.fileReady,
        files: []
      }
      const path = order.filePath
      order.files.forEach(file => {
        // TODO check if file.fileExists is false and handle it.
        parsedOrder.files.push({
          path,
          filename: file.filename,
          size: file.fileSize,
          url: file.fileUrl
        })
      })
      parsed.push(parsedOrder)
    })
    state.statusGet = 'success'
    state.dataFME = parsed
  },
  successPost (state) {
    state.statusPost = 'success'
  },
  errorGet (state) {
    state.statusGet = 'error'
  },
  errorPost (state) {
    state.statusPost = 'error'
  },
  clearState (state) {
    state.statusGet = ''
    state.statusPost = ''
    state.data = []
  }
}

export const DownloadData = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
