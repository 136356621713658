'use strict'

import { arrayEqual } from '@/HelperFunctions'
import 'midi-fur-alles.js/src/midi-fur-alles'

;((d) => {
  // eslint-disable-next-line one-var
  const lastKeyTime = Date.now(),
    combo = ['arrowup', 'arrowdown', 'arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'arrowleft', 'arrowright', 'a', 'b']
  let buffer = []
  d.addEventListener('keydown', event => {
    // eslint-disable-next-line one-var
    const key = event.key.toLowerCase(),
      currentKeyTime = Date.now()
    if (combo[buffer.length] !== key) {
      buffer = []
      return
    }
    if ((lastKeyTime - currentKeyTime) > 500) { buffer = [] }
    buffer.push(key)
    if (arrayEqual(combo)(buffer)) {
      // eslint-disable-next-line no-undef
      const player = new MidiFurAlles({ baseUrl: 'dev/', instruments: 'dev/' })
      player.load('GirlFromIpanema.mid')
      player.play()
    }
    return true
  }, { capture: false, once: false, passive: true })
})(document)
