import { Auth } from './Auth'
import { Profile } from './Profile'
import { SubUsers } from './SubUsers'
import { UserTypes } from './UserTypes'
import { CreateUser } from './CreateUser'
import { Tokens } from './Tokens'
import { Subscriptions } from './Subscriptions'
import { ResetPassword } from './ResetPassword'
import { DownloadData } from './DownloadData'
import { Confirm } from './Confirm'

export default {
  Auth,
  Profile,
  SubUsers,
  UserTypes,
  CreateUser,
  Tokens,
  Subscriptions,
  ResetPassword,
  DownloadData,
  Confirm
}
