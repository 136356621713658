import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { productsSearch } from '@/store/shared/Paths'
import { mGetId } from '@/store/shared/Queries'
import { parseDataset } from '@/store/shared/ParseData'
import { toRaw } from 'vue'

// eslint-disable-next-line one-var
const parseData = (data) => {
    // eslint-disable-next-line one-var
    const state = [],
      array = data.hits ? data.hits.hits : data.docs
    for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
      state[c] = parseDataset(array[i])
    }
    return state
  },
  crud = new ElasticSearch(mGetId, productsSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

getters.userHasAccess = (state, getters, rootState) => {
  // Can't use .filter or .find on the proxy array for some reason, so using forEach instead.
  const loginFilters = toRaw(rootState.DatasetsFilters.loginFilters || [])
  const userGroups = rootState.Profile.data?.groups || []
  const loginFiltersAdjusted = []
  loginFilters.forEach(filter => {
    let allowed = false
    userGroups.forEach(e => {
      if (e.title === filter.title) {
        allowed = true
      }
    })
    if (!allowed) {
      loginFiltersAdjusted.push(filter)
    }
  })

  let userHasAccess = true
  state.data.forEach(dataset => {
    dataset.tags.forEach(tag => {
      if (tag.type === 'tag') {
        loginFiltersAdjusted.forEach(e => {
          if (e.id === tag.id) {
            userHasAccess = false
          }
        })
      }
    })
  })
  return userHasAccess
}

getters.metadataId = (state) => {
  const dataset = state.data[0]
  if (!dataset || dataset.description.length !== 36) return null
  return dataset.description
}

export const Dataset = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
