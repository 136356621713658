import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { labsSearch } from '@/store/shared/Paths'
import { mGetId } from '@/store/shared/Queries'
import { parseLab } from '@/store/shared/ParseData'

// eslint-disable-next-line one-var
const parseData = (data) => {
    // eslint-disable-next-line one-var
    const state = [],
      array = data.hits ? data.hits.hits : data.docs
    for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
      state[c] = parseLab(array[i])
    }
    return state
  },
  crud = new ElasticSearch(mGetId, labsSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const Lab = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
