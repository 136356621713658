import {
  webserviceFilterType,
  downloadFilterType,
  cutouts,
  downloadUrl,
  downloadUrlTest,
  mobileMaxWidth,
  defaultHeroGradient,
  defaultHeroGradientMobile
} from '@/Constants'

export const getTagById = (findId, store) => {
  const tags = store?.state?.Tags?.data || store.$store.state?.Tags?.data
  if (!tags) {
    return null
  }
  return tags.find(tag => {
    return tag.id === findId
  })
}

export const getFormatTypeById = (findId, store) => {
  if (!store.state.DatasetsAttributes) {
    return null
  }
  const attributes = store.state.DatasetsAttributes.data
  for (let i = 0; i < attributes.length; i++) {
    const match = attributes[i].list.find(({ id }) => {
      return id === findId
    })
    if (match) {
      return match
    }
  }
  return null
}

export const formatIcon = (attributeType, type, cutout) => {
  if (attributeType === webserviceFilterType) {
    if (type === 'cap') { // has capability file
      return 'download'
    }
    return 'exitsite'
  } else if (attributeType === downloadFilterType) {
    if (cutout === cutouts.all || cutout === cutouts.all2) {
      return 'download'
    } else if (type === 'ftp') {
      return 'copy'
    } else if (type === 'http') {
      return 'exitsite'
    } else if (type === 'map' || type === 'fme') {
      return 'choose'
    }
  }
  return 'download'
}

export const formatText = (attributeType, type, cutout) => {
  if (attributeType === webserviceFilterType) {
    if (type === 'cap') { // has capability file
      return 'Hent'
    }
    return 'Gå til dokumentation'
  } else if (attributeType === downloadFilterType) {
    if (cutout === cutouts.all || cutout === cutouts.all2) {
      return 'Hent'
    } else if (type === 'ftp') {
      return 'Kopier FTP link'
    } else if (type === 'http') {
      return 'Gå til download'
    } else if (type === 'map' || type === 'fme') {
      return 'Vælg område'
    }
  }
  return 'Hent'
}

const hex2rgbObj = (hex) => {
  if (!hex) hex = [6]
  return {
    r: ('0x' + hex[1] + hex[2]) | 0,
    g: ('0x' + hex[3] + hex[4]) | 0,
    b: ('0x' + hex[5] + hex[6]) | 0
  }
}

export const hex2rgb = (hex, opacity) => {
  const rgb = hex2rgbObj(hex)
  return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')'
}

export const hex2rgbSolid = (hex, opacity) => {
  const addOpacity = (num, op) => {
    return 255 - op * (255 - num)
  }
  const rgb = hex2rgbObj(hex)
  return 'rgb(' + addOpacity(rgb.r, opacity) + ',' + addOpacity(rgb.g, opacity) + ',' + addOpacity(rgb.b, opacity) + ')'
}

export const copyToClipboard = (text) => {
  return window.navigator.clipboard.writeText((text || '')).then(
    () => { return { state: 'success' } },
    (msg) => { return { state: 'fail', error: msg } }
  )
}

export const arrayCompare = f => ([x, ...xs]) => ([y, ...ys]) =>
  x === undefined && y === undefined
    ? true
    : Boolean(f(x)(y)) && arrayCompare(f)(xs)(ys)

export const arrayEqual = arrayCompare(x => y => x === y)

export const request = async (url) => {
  try {
    // eslint-disable-next-line one-var
    const response = await fetch(url),
      data = await response.text()
    return data
  } catch (err) {
    return err
  }
}

// TODO: make this work properly
export const unqiueArrayOfObjects = (arr) => {
  // eslint-disable-next-line one-var
  const a = arr || [], b = []
  // eslint-disable-next-line no-unused-vars
  let prevItem = {}
  for (let i = 0, iEnd = a.length, x = 0; i < iEnd; ++i) {
    // eslint-disable-next-line one-var
    const curItem = a[i],
      entries = Object.entries(curItem),
      prevEntries = Object.entries(prevItem);
    (entries.every((elm, index) => { return (index in prevEntries) ? ((elm[0] !== prevEntries[index][0]) && (elm[1] !== prevEntries[index][1])) : true }) && (b[x] = curItem) && (++x))
    prevItem = curItem
  }
  return b
}

// requires data.tags to be a correct list of tags
export const filterByTags = (data, filterGroups) => {
  return data.filter(el => {
    let matchAll = true
    for (let i = 0; i < filterGroups.length; i++) {
      const filters = filterGroups[i].filters
      let matchAtleastOne = false
      for (let j = 0; j < filters.length; j++) {
        const filter = filters[j]
        const match = el.tags.find(tag => {
          return tag.id === filter.id && tag.type === filter.type
        })
        if (match) {
          matchAtleastOne = true
          break
        }
      }
      if (!matchAtleastOne) {
        matchAll = false
        break
      }
    }
    return matchAll
  })
}

// filter a list of datasets by a search string
export const filterBySearch = (data, searchText, self) => {
  if (!searchText) {
    return data
  }
  return data.filter(dataset => {
    const searchFields = []
    const split = searchText.toLowerCase().split(' ')
    searchFields.push(dataset.title.toLowerCase())
    searchFields.push(dataset.summary.toLowerCase())
    const services = self.$store.state?.DatasetsServices?.data
    let servicesString = ''
    dataset.services.forEach(serviceRef => {
      const service = services.find(e => {
        return e.id === serviceRef.id
      })
      if (service) {
        servicesString += service.name + ' ' + service.title + ' '
      } else {
        // console.error('Can not find service with id: ' + serviceRef.id)
      }
    })
    searchFields.push(servicesString.toLowerCase())
    dataset.tags.forEach(tag => {
      const format = getFormatTypeById(tag.id, self.$store)
      if (format) {
        searchFields.push(format.title.toLowerCase())
      }
    })
    const nonMatch = split.find(function (f) {
      const match = searchFields.find(field => {
        return field.indexOf(f) !== -1
      })
      return !match
    })
    return !nonMatch
  })
}

/**
 * remove apostrophes at start and end of text string, used for strings that elastic seach
 * have to escape using single apostrophes, but have doubles added on top to make it valid JSON.
 */
export const removeApostrophes = string => {
  const query = /^'|'$/ig
  if (string.match(query)?.length === 2) {
    return string.replaceAll(query, '')
  }
  return string
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp
  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
  return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * Format area output.
 * @param {Number} area The size of the area in meters.
 * @return {string} Formatted area.
 */
export const formatArea = function (area) {
  let output
  if (area > 10000) {
    output = '&#126; ' + Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>'
  } else {
    output = '&#126; ' + Math.round(area * 1) / 1 + ' ' + 'm<sup>2</sup>'
  }
  return output
}

// gets the download url based on the environment. get the environment using the following: process.env.NODE_ENV
export const getDownloadUrl = (env) => {
  return (env === 'production') ? downloadUrl : downloadUrlTest
}

// gets the default hero gradient based on the window width
export const getDefaultHeroGradient = () => {
  return window.innerWidth <= mobileMaxWidth ? defaultHeroGradientMobile : defaultHeroGradient
}
