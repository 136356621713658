import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseUptimeTables } from '@/store/shared/ParseData'
import { uptimeTablesSearch } from '@/store/shared/Paths'

const query = () => {
  return {}
}
const crud = new ElasticSearch(query, uptimeTablesSearch, parseUptimeTables)
const state = crud.state
const getters = crud.getters
const actions = crud.actions
const mutations = crud.mutations

export const UptimeTables = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
