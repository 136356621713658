import axios from 'axios'
import router from '@/router'
const baseUrl = window.location.origin + '/user-api/'
const url = baseUrl + 'login/'
const urlRefresh = baseUrl + 'refreshToken/'

// initial state
const state = {
  token: localStorage.getItem('user-token') || '',
  refreshToken: localStorage.getItem('refresh-token') || '',
  status: ''
}

// getters
const getters = {
  isAuthenticated (state) { return !!state.token },
  authStatus (state) { return state.status }
}

// actions
const actions = {
  request ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.post(url, {
        username: user.username,
        password: user.password
      }, { timeout: 10000 })
        .then((resp) => {
          const token = 'Bearer ' + resp.data.access_token
          if (token) { localStorage.setItem('user-token', token) }

          const refreshToken = resp.data.refresh_token
          if (refreshToken) { localStorage.setItem('refresh-token', refreshToken) }
          commit('success', { token, refreshToken })
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          localStorage.removeItem('user-token')
          reject(err)
        })
    })
  },
  refresh ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      // Check if refresh token exists
      if (!state.refreshToken) {
        const err = 'No refresh token found in local storage'
        commit('error', err)
        localStorage.removeItem('refresh-token')
        reject(err)
        return
      }
      commit('request')
      axios.get(urlRefresh + state.refreshToken, { timeout: 10000 })
        .then((resp) => {
          const token = 'Bearer ' + resp.data.access_token
          if (token) { localStorage.setItem('user-token', token) }
          const refreshToken = resp.data.refresh_token
          if (refreshToken) { localStorage.setItem('refresh-token', refreshToken) }
          commit('success', { token, refreshToken })
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          localStorage.removeItem('user-token')
          reject(err)
        })
    })
  },
  logOut ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('logOut')
      dispatch('Profile/clear', {}, { root: true })
      dispatch('Tokens/clear', {}, { root: true })
      localStorage.removeItem('user-token')
      localStorage.removeItem('refresh-token')
      const url = new URL(window.location.href)
      if (url.pathname === '/user') router.push('/')
      resolve()
    })
  },
  clearOldToken ({ commit, dispatch }) {
    commit('clearOldToken')
    localStorage.removeItem('user-token')
  }
}

// mutations
const mutations = {
  request (state) {
    state.status = 'loading'
  },
  success (state, tokens) {
    state.status = 'success'
    state.token = tokens.token
    state.refreshToken = tokens.refreshToken
  },
  error (state) {
    state.status = 'error'
  },
  logOut (state) {
    state.token = ''
    state.refreshToken = ''
    state.status = ''
  },
  clearOldToken (state) {
    state.token = ''
  }
}

export const Auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
