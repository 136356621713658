import axios from 'axios'
import { ElasticSearch } from '@/store/shared/ElasticSearch'

const htmlize = (str) => {
  return str.replace(/(https:\/\/)(\S*)/g, '<a target="_blank" href="$1$2">$2</a>') // replace hyperlinks
    .replace(/(?:\r\n|\r|\n)/g, '<br>') // replace new lines
}

const ensureArray = (obj) => {
  return obj[0] ? obj : [obj]
}

const parseData = (data) => {
  const dataQualityInfo = ensureArray(data?.['mdb:dataQualityInfo']?.['mdq:DQ_DataQuality']?.['mdq:report'])
  const usabilityInfo = dataQualityInfo.find(el => { return el['mdq:DQ_UsabilityElement'] })
  const completenessInfo = dataQualityInfo.find(el => { return el['mdq:DQ_CompletenessOmission'] })
  const meta = {
    summary: data?.['mdb:identificationInfo']?.['mri:MD_DataIdentification']?.['mri:abstract']?.['gco:CharacterString']?.['#text'] || '',
    description: htmlize(data?.['mdb:identificationInfo']?.['mri:MD_DataIdentification']?.['mri:purpose']?.['gco:CharacterString']?.['#text'] || ''),
    origin: htmlize(data?.['mdb:resourceLineage']?.['mrl:LI_Lineage']?.['mrl:statement']?.['gco:CharacterString']?.['#text'] || ''),
    goodToKnow: htmlize(data?.['mdb:identificationInfo']?.['mri:MD_DataIdentification']?.['mri:supplementalInformation']?.['gco:CharacterString']?.['#text'] || ''),
    usability: usabilityInfo?.['mdq:DQ_UsabilityElement']?.['mdq:result']?.['mdq:DQ_DescriptiveResult']?.['mdq:statement']?.['gco:CharacterString']?.['#text'] || '',
    maintenance: data?.['mdb:identificationInfo']?.['mri:MD_DataIdentification']?.['mri:resourceMaintenance']?.['mmi:MD_MaintenanceInformation']?.['mmi:maintenanceNote']?.['gco:CharacterString']?.['#text'] || '',
    completeness: completenessInfo?.['mdq:DQ_CompletenessOmission']?.['mdq:result']?.['mdq:DQ_DescriptiveResult']?.['mdq:statement']?.['gco:CharacterString']?.['#text'] || '',
    links: []
  }
  const transferOptions = ensureArray(data?.['mdb:distributionInfo']?.['mrd:MD_Distribution']?.['mrd:transferOptions'])
  transferOptions.forEach(to => {
    const resources = ensureArray(to?.['mrd:MD_DigitalTransferOptions']?.['mrd:onLine'])
    resources.forEach(resource => {
      const linkInfo = resource?.['cit:CI_OnlineResource']
      if (linkInfo?.['cit:function']?.['cit:CI_OnLineFunctionCode']?.['@codeListValue'] !== 'information') return
      const link = linkInfo?.['cit:linkage']?.['gco:CharacterString']?.['#text'] || ''
      const title = linkInfo?.['cit:name']?.['gco:CharacterString']?.['#text'] || ''
      const description = linkInfo?.['cit:description']?.['gco:CharacterString']?.['#text'] || ''
      if (link && title) {
        meta.links.push({ link, title, description, icon: 'exitsite', target: '_blank' })
      }
    })
  })
  return [meta]
}
const crud = new ElasticSearch(null, '', parseData)
const state = crud.state
const getters = crud.getters
const actions = crud.actions
const mutations = crud.mutations

actions.get = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    commit('request')
    axios.get(window.location.origin + '/metadata/' + id)
      .then((resp) => {
        commit('success', resp.data)
        resolve(resp)
      })
      .catch((err) => {
        commit('error', err)
        reject(err)
      })
  })
}

export const DatasetMetadata = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
