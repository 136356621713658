import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseLabs } from '@/store/shared/ParseData'
import { labsSearch } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const query = () => {
    // eslint-disable-next-line multiline-ternary
    return (process.env.VUE_APP_SHOW_UNPUBLISHED === 'true') ? {
      size: 10000,
      sort: [
        { 'lab_title.keyword': { order: 'desc' } }
      ]
    } : {
      size: 10000,
      sort: [
        { 'lab_title.keyword': { order: 'desc' } }
      ],
      query: {
        match: {
          o_published: true
        }
      }
    }
  },
  crud = new ElasticSearch(query, labsSearch, parseLabs),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const Labs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
