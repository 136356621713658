// TODO: clean this up and optimize

import { tagFilterType, webserviceFilterType, downloadFilterType, coordinateFilterType, downloadFormatId } from '@/Constants'
import { removeApostrophes } from '@/HelperFunctions'

export const parseAssets = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs

  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    const curItem = array[i]._source
    // if (!curItem.id) { continue } // do not increase c
    state[c] = {
      id: curItem.id,
      path: curItem.path,
      filename: curItem.filename
    }
  }
  /*
  array.forEach((e) => {
    const asset = {
      id: e._source.id,
      path: e._source.path,
      filename: e._source.filename
    }
    state.push(asset)
  })
  */
  return state
}

export const parseCases = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  array.forEach((e) => {
    state.push(parseCase(e))
  })
  return state.sort((a, b) => {
    // eslint-disable-next-line one-var
    const nameA = a.title.toUpperCase(),
      nameB = b.title.toUpperCase()
    if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export const parseDatasets = (data) => {
// TODO: use associative array (named objects) for faster find lookups.
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    state[c] = parseDataset(array[i])
  }
  return state
}

export const parseApps = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  array.forEach((e) => {
    state.push(parseApp(e))
  })
  return state.sort((a, b) => {
    // eslint-disable-next-line one-var
    const nameA = a.title.toUpperCase(),
      nameB = b.title.toUpperCase()
    if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export const parseLabs = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    state[c] = parseLab(array[i])
  }
  return state
  /*
  array.forEach((e) => {
    state.push(parseLab(e))
  })
  return state.sort((a, b) => {
    // eslint-disable-next-line one-var
    const nameA = a.title.toUpperCase(),
      nameB = b.title.toUpperCase()
    if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    }
    return 0
  })
  */
}

export const parseServices = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    state[c] = parseService(array[i])
  }
  return state
  /*
  return state.sort((a, b) => {
    const nameA = a.title.toUpperCase()
    const nameB = b.title.toUpperCase()
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0
  })
  */
}

export const parseNews = (data) => {
  // eslint-disable-next-line one-var
  const state = [],
    array = data.hits ? data.hits.hits : data.docs
  array.forEach((e) => {
    // state.push(parseNew(e))
    const news = parseNew(e)
    if (news.date) {
      state.push(news)
    }
  })
  const months = [
    'januar',
    'febuar',
    'marts',
    'april',
    'maj',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'december'
  ]
  return state.sort((a, b) => {
    const dateA = a.date.split(' ')
    const dateB = b.date.split(' ')
    const yearA = Number(dateA[2])
    const yearB = Number(dateB[2])
    if (yearA < yearB) {
      return 1
    } else if (yearA > yearB) {
      return -1
    }
    const monthA = months.findIndex((e) => {
      return e === dateA[1]
    })
    const monthB = months.findIndex((e) => {
      return e === dateB[1]
    })
    if (monthA < monthB) {
      return 1
    } else if (monthA > monthB) {
      return -1
    }
    const dayA = Number(dateA[0].slice(0, -1))
    const dayB = Number(dateB[0].slice(0, -1))
    if (dayA < dayB) {
      return 1
    } else if (dayA > dayB) {
      return -1
    }
    return 0
  })
}

export const parseCase = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    cases = (exist) ? {
      type: 'case',
      id: source.o_id || '',
      title: source.case_title || '',
      shortTitle: source.case_shorttitle || '',
      summary: source.case_summary || '',
      image: source.case_hero || '',
      relWebsites: source.case_rel_websites || [],
      relDatasets: source.case_rel_products || [],
      tags: [],
      sectionContents: source.case_section_content || [],
      quotes: [],
      factTitle: source.case_factbox_title || '',
      factContent: source.case_factbox_content || ''
    } : {
      type: 'case',
      id: '',
      title: '',
      shortTitle: '',
      summary: '',
      image: '',
      relWebsites: [],
      relDatasets: [],
      tags: [],
      sectionContents: [],
      quotes: [],
      factTitle: '',
      factContent: ''
    }
  if (exist && source.tag_paths) {
    for (let i = 0, iEnd = source.tag_paths.length; i < iEnd; ++i) {
      cases.tags[i] = {
        id: Number(source.tag_ids[i]),
        type: tagFilterType
        // title: source.tag_names[i]
      }
    }
  }

  if (exist && source.case_quote_content) {
    cases.quotes = source.case_quote_content.map((quote, index) => {
      if (!quote) return null
      return {
        content: quote,
        image: source.case_quote_image[index],
        source: source.case_quote_source[index]
      }
    }).filter(Boolean)
  }

  // HTML escape content strings
  cases.sectionContents = cases.sectionContents.map(section => {
    return section.replaceAll('&quot;', '"')
  })

  return cases
}

export const parseDataset = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    dataset = (exist) ? {
      type: 'dataset',
      id: source.o_id || '',
      title: (source.product_shorttitle || source.product_title) || '',
      tooltip: source.product_title || '',
      summary: source.product_summary || '',
      description: removeApostrophes(source.product_description || '') || '',
      description_tmp: removeApostrophes(source.product_description_tmp || '') || '',
      image: source.product_image || 0,
      tags: [],
      downloads: [],
      services: [],
      latitude: source.product_latitude || '',
      longitude: source.product_longitude || '',
      zoom: source.product_zoom || '',
      dataStructure: source.product_datastructure || '',
      relevance: source.product_relevance || '',
      dataScheme: source.product_datascheme || '',
      overlay: source.product_overlay || '',
      overlayId: Number(source.product_webservice) || null, // TODO: remove Number() when ES returns it as a number instead of a string.
      layer: source.product_layer || '',
      linkTitle: source.product_linktitle || '',
      linkUrl: source.product_linkurl || '',
      relTitles: source.product_rel_titles || '',
      relCases: source.product_rel_cases || [],
      purpose: source.dataproduct_purpose || '',
      dataProductIds: [],
      mapPlaceHolderImageId: source?.product_previews.length > 0 ? source.product_previews[0] : null,
      dataDeclarationId: source?.product_previews.length > 1 ? source.product_previews[1] : null,
      mapLink_tmp: source.product_tmpmaplink || '',

      termsLink: source.product_termslink || null,
      termsLink2: source.product_termslink2 || null
    } : {
      type: 'dataset',
      id: '',
      title: '',
      tooltip: '',
      summary: '',
      description: '',
      description_tmp: '',
      image: 0,
      tags: [],
      downloads: [],
      services: [],
      latitude: '',
      longitude: '',
      zoom: '',
      dataStructure: '',
      relevance: '',
      dataScheme: '',
      overlay: '',
      layer: '',
      linkTitle: '',
      linkUrl: '',
      relTitles: '',
      relCases: [],
      purpose: '',
      dataProductIds: [],
      mapPlaceHolderImageId: null,
      dataDeclarationId: null,
      termsLink: null,
      termsLink2: null
    }
  if (exist && source.product_relations) {
    // temp fix for source.product_httpdescription sometimes being a string when it should be an array
    let descriptionArray = source.product_httpdescription
    if (!Array.isArray(descriptionArray)) {
      // rm first and last two characters '["' & '"]'
      const trimmed = descriptionArray.substring(2, descriptionArray.length - 2)
      // split by '","'
      descriptionArray = trimmed.split('","')
    }
    for (let i = 0, iEnd = source.product_relations.length; i < iEnd; ++i) {
      if (source.product_ftpurl[i]) {
        dataset.downloads[i] = {
          type: 'ftp',
          id: i,
          link: source.product_ftpurl[i],
          title: source.product_ftptitel[i],
          subtitle: source.product_ftpsubtitel[i],
          description: source.product_ftpdescription[i],
          formatIds: [downloadFormatId.ftp], // set format id of FTP to 'ftp'.
          coordIds: source.product_relations[i].coord || []
        }
      } else if (source.product_httpurl[i]) {
        dataset.downloads[i] = {
          type: 'http',
          id: i,
          link: source.product_httpurl[i],
          title: source.product_httptitel[i],
          subtitle: source.product_httpsubtitel[i],
          description: descriptionArray[i],
          formatIds: source.product_relations[i].format || [],
          coordIds: source.product_relations[i].coord || []
        }
      } else if (source.product_fmename[i]) {
        dataset.downloads[i] = {
          type: 'fme',
          id: i,
          title: source.product_shorttitle,
          subtitle: source.product_fmecutouts[i],
          formatIds: source.product_relations[i].format || [],
          coordIds: source.product_relations[i].coord || [],
          name: source.product_fmename[i], // file name?
          cutouts: source.product_fmecutouts[i]
        }
      } else if (source.product_mapcutouts[i]) {
        dataset.downloads[i] = {
          type: 'map',
          id: i,
          title: source.product_shorttitle,
          subtitle: source.product_mapcutouts[i],
          formatIds: source.product_relations[i].format || [],
          coordIds: source.product_relations[i].coord || [],
          cutouts: source.product_mapcutouts[i],
          cutoutLink: Number(source.product_mapcutoutlink[i]), // this is the link ID. We need to make a new call similar to how we get images to get the real link.
          cutoutFolder: source.product_mapcutoutfolder[i],
          cutoutFileName: source.product_mapcutoutdetails[i]
        }
      }
    }
  }
  if (exist && source.servicetypeid) {
    for (let i = 0, iEnd = source.servicetypeid.length; i < iEnd; ++i) {
      dataset.services[i] = {
        id: source.product_rel_services[i],
        typeId: source.servicetypeid[i]
      }
    }
  }
  if (exist && source.tag_paths) {
    for (let i = 0, iEnd = source.tag_paths.length; i < iEnd; ++i) {
      dataset.tags[i] = {
        id: Number(source.tag_ids[i]),
        type: tagFilterType
        // title: source.tag_names[i]
      }
    }
  }
  if (exist) {
    // tags such as download and webservice format
    const attributes = []
    dataset.downloads.forEach(download => {
      download.formatIds.forEach(id => {
        attributes.push({
          id,
          type: downloadFilterType
        })
      })
      download.coordIds.forEach(id => {
        attributes.push({
          id,
          type: coordinateFilterType
        })
      })
    })
    if (source.servicetypeid) {
      source.servicetypeid.forEach(servicetype => {
        attributes.push({
          id: servicetype,
          type: webserviceFilterType
        })
      })
    }
    const uniqueAttributes = Array.from(new Set(attributes.map((e) => {
      return e.id
    })))
      .map((id) => {
        return attributes.find((e) => {
          return e.id === id
        })
      })
    dataset.tags = [...dataset.tags, ...uniqueAttributes]
  }
  // dataproduct relations
  if (exist && source.product_rel_dataproducts) {
    source.product_rel_dataproducts.forEach((d) => dataset.dataProductIds.push(d))
  }
  return dataset
}

export const parseApp = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    app = (exist) ? {
      type: 'app',
      id: source.o_id || '',
      title: source.web_title || '',
      description: source.web_description || '',
      image: source.web_image || '',
      icon: source.web_icon || '',
      link: source.web_link || ''
    } : {
      type: 'app',
      id: '',
      title: '',
      description: '',
      image: '',
      icon: '',
      link: ''
    }
  return app
}

export const parseLab = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    lab = (exist) ? {
      type: 'lab',
      id: source.o_id || '',
      title: source.lab_title || '',
      summary: source.lab_summary || '',
      image: source.lab_image || -1,
      justify: source.lab_justify || '',
      link: source.lab_link || '',
      contactimage: source.lab_contactimage || -1,
      contactname: source.lab_contactname || '',
      contactphone: source.lab_contactphone || '',
      contactmail: source.lab_contactmail || '',
      prototitle: source.lab_prototitle || '',
      protodesc: source.lab_protodesc || '',
      howtitle: source.lab_howtitle || '',
      howdesc: source.lab_howdesc || '',
      trytitle: source.lab_trytitle || '',
      trydesc: source.lab_trydesc || '',
      suggesttitle: source.lab_suggesttitle || '',
      suggestdesc: source.lab_suggestdesc || ''
    } : {
      type: 'lab',
      id: '',
      title: '',
      summary: '',
      image: '',
      justify: '',
      link: '',
      contactname: '',
      contactphone: '',
      contactmail: '',
      prototitle: '',
      protodesc: '',
      howtitle: '',
      howdesc: '',
      trytitle: '',
      trydesc: '',
      suggesttitle: '',
      suggestdesc: ''
    }
  return lab
}

export const parseService = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    service = (exist) ? {
      id: source.o_id || '',
      title: source.service_title || '',
      name: source.service_name || '',
      typeId: source.service_servicetype || '',
      typeName: source.service_servicetypename || '',
      description: source.service_description || '',
      image: source.service_image || '',
      tokenRequired: source.service_tokenrequired !== 'no',
      link: source.service_link || ''
    } : {
      id: '',
      title: '',
      name: '',
      typeId: '',
      typeName: '',
      description: '',
      image: '',
      token: '',
      link: ''
    }
  return service
}

export const parseDataProduct = (data) => {
  const source = data?._source
  const service = {
    description: source?.dataproduct_description || '',
    accessrestrictions: source?.dataproduct_accessrestrictions || '',
    alternativetitle: source?.dataproduct_alternativetitle || '',
    identificator: source?.dataproduct_identificator || '',
    image: source?.dataproduct_image || '',
    origin: source?.dataproduct_origin || '',
    purpose: source?.dataproduct_purpose || '',
    rel_features: source?.dataproduct_rel_features || [],
    rel_products: source?.dataproduct_rel_products || [],
    rel_registers: source?.dataproduct_rel_registers || [],
    terms: source?.dataproduct_terms || '',
    title: source?.dataproduct_title || '',
    updatefrequency: source?.dataproduct_updatefrequency || ''
  }
  return service
}

export const parseGeojson = (data) => {
  const json = {}
  return json
}

export const parseNew = (data) => {
  // eslint-disable-next-line one-var
  const exist = !!data._source,
    source = (exist) ? data._source : '',
    // eslint-disable-next-line multiline-ternary
    news = (exist) ? {
      type: 'news',
      id: source.o_id || '',
      title: source.news_title || '',
      summary: source.news_summary || '',
      image: source.news_image || 0,
      date: source.news_date || '',
      content: source.news_content || '',
      parent: source.o_parentid || 0
    } : {
      type: 'news',
      id: '',
      title: '',
      summary: '',
      image: 0,
      date: '',
      content: ''
    }
  return news
}

export const parseTags = (data) => {
  // eslint-disable-next-line one-var
  const tags = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    const curItem = array[i]._source
    tags[c] = {
      id: curItem.tag_id,
      title: curItem.tag_name,
      parentId: curItem.tag_parentid
    }
  }

  return tags
}

export const parseProfile = (data) => {
  const userGroups = []
  data.groups?.forEach(e => {
    userGroups.push({
      id: e.id,
      title: e.name
    })
  })
  return {
    id: data.id,
    firstname: data.firstname || '',
    lastname: data.lastname || '',
    addresses: data.addresses || [],
    emails: data.emails || [],
    phonenumbers: data.phonenumbers || [],
    flag_accept_gdpr: data.flag_accept_gdpr,
    flag_accept_terms: data.flag_accept_terms,
    flag_contact_ok: data.flag_contact_ok,
    groups: userGroups,
    username: data.username || '',
    usertype: data.usertype,
    usertype_id: data.usertype_id,
    masteruser: data.masteruser,
    maxArea: data.maxarea,
    expire: data.expiration_date === null ? null : new Date(data.expiration_date)
  }
}
export const parseUptimeTables = (data) => {
  // eslint-disable-next-line one-var
  const uptimeTables = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    const curItem = array[i]._source
    uptimeTables[c] = {
      uptimeMatrix: JSON.parse(curItem.matrix),
      recordedYear: curItem.year
    }
  }
  uptimeTables.sort((a, b) => { return b.recordedYear - a.recordedYear })
  return uptimeTables
}

export const parseFaq = (data) => {
  // eslint-disable-next-line one-var
  const faq = [],
    array = data.hits ? data.hits.hits : data.docs
  for (let i = array.length - 1, c = 0; i > -1; --i, ++c) {
    const curItem = array[i]._source
    faq[c] = {
      id: curItem.o_id,
      sortIndex: curItem.o_index,
      question: curItem.question,
      answer: curItem.answer,
      parentFolderId: curItem.parentfolderid,
      parentFolderName: curItem.parentfoldername
    }
  }

  return faq
}
