import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { tagsSearch } from '@/store/shared/Paths'
import { parseTags } from '@/store/shared/ParseData'

// eslint-disable-next-line one-var
const query = () => {
    return {
      size: 9999,
      sort: [
        { 'tag_name.keyword': { order: 'desc' } }
      ]
    }
  },
  crud = new ElasticSearch(query, tagsSearch, parseTags),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const Tags = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
