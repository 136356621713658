import axios from 'axios'
import { parseProfile } from '../shared/ParseData'

const baseUrl = window.location.origin + '/user-api/'

const urlUser = baseUrl + 'user/'
const urlUsername = baseUrl + 'username/'
const urlAvailable = baseUrl + 'username/isAvailable/'
const urlPassword = baseUrl + 'password/'
const urlEmail = baseUrl + 'email/'

// initial state
const state = {
  status: '',
  statusGet: '',
  data: null,
  errorMessage: '',
  successMessage: ''
}

// getters
const getters = {
  current (state) { return state.data },
  statusGet (state) { return state.statusGet },
  status (state) { return state.status }
}

// actions
const actions = {
  get ({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      commit('requestGet')
      axios.get(urlUser, { timeout: 10000 })
        .then((resp) => {
          commit('successGet', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  clear ({ commit, dispatch }, type) {
    commit('clearProfile', type)
  },
  put ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUser, user, { timeout: 10000 }) // + rootState.SubUsers.selectedUser, state.data)
        .then((resp) => {
          commit('successPut', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  deleteUser ({ commit, dispatch, rootState }, password) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.delete(urlUser, // + rootState.SubUsers.selectedUser,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password
          },
          timeout: 10000
        })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  deleteEmail ({ commit, dispatch, rootState }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.delete(urlEmail, // + rootState.SubUsers.selectedUser,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            email_id: id
          },
          timeout: 10000
        })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  postEmail ({ commit, dispatch, rootState }, email) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.post(urlEmail, email, { timeout: 10000 })
      // axios.post(urlEmail + rootState.SubUsers.selectedUser, email)
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  resendEmail ({ commit, dispatch, rootState }, email) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.get(urlEmail + 'resendConfirmation/' + (rootState.SubUsers.selectedUser ? rootState.SubUsers.selectedUser : state.data.id) + '/' + email,
        { timeout: 10000 })
        .then((resp) => {
          commit('success', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  getAvailability ({ commit, dispatch }, value) {
    return new Promise((resolve, reject) => {
      axios.get(urlAvailable + value, { timeout: 10000 })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePasswordByToken ({ commit, dispatch, rootState }, { token, password }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlPassword, { token, password, password_confirmed: password }, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('errorPassword', err)
          reject(err)
        })
    })
  },
  updatePassword ({ commit, dispatch, rootState }, { oldPassword, newPassword }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlPassword, { old_password: oldPassword, password: newPassword }, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('errorPassword', err)
          reject(err)
        })
    })
  },
  updateUsername ({ commit, dispatch, rootState }, value) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUsername + rootState.SubUsers.selectedUser, value, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  updateSingle ({ commit, dispatch, rootState }, data) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUser, data, { timeout: 10000 })// + rootState.SubUsers.selectedUser, data)
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  acceptConsent ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUser + 'gdprAccept', { accept_gdpr: true }, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  acceptTerms ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('request')
      axios.put(urlUser + 'termsAccept', { accept_terms: true }, { timeout: 10000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  idle () {
    state.status = 'idle'
  },
  request (state) {
    state.status = 'loading'
  },
  requestGet (state) {
    state.statusGet = 'loading'
  },
  success (state, resp) {
    if (!resp || resp.data.charAt(0) === '{') {
      state.successMessage = ''
    } else {
      state.successMessage = resp.data
    }
    state.status = 'success'
    setTimeout(() => { state.status = 'idle' }, 5000)
  },
  successGet (state, data) {
    state.statusGet = 'success'
    state.data = parseProfile(data)
  },
  successPut (state, data) {
    state.data = parseProfile(data)
    state.status = 'success'
    state.successMessage = ''
    setTimeout(() => { state.status = 'idle' }, 5000)
  },
  clearProfile (state) {
    state.status = ''
    state.statusGet = ''
    state.data = null
    state.errorMessage = ''
    state.successMessage = ''
  },
  error (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'error'
    setTimeout(() => { state.status = 'idle' }, 5000)
  },
  errorPassword (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'idle'
  },
  errorGet (state) {
    state.statusGet = 'error'
  },
  createEntry (state, type) {
    if (type === 'phonenumbers') {
      state.data.phonenumbers.push({ phonenumber: '', id: 'new' + Date.now() })
    } else if (type === 'addresses') {
      state.data.addresses.push({ address: '', city: '', postal: '', id: 'new' + Date.now() })
    } else {
      const errorMessage = 'Can not create type "' + type + '"'
      throw errorMessage
    }
  },
  updateEntry (state, { type, id, value }) {
    if (type === 'phonenumbers') {
      const entry = state.data.phonenumbers.find((e) => { return e.id === id })
      entry.phonenumber = value
    } else if (type === 'address') {
      const entry = state.data.addresses.find((e) => { return e.id === id })
      entry.address = value
    } else if (type === 'city') {
      const entry = state.data.addresses.find((e) => { return e.id === id })
      entry.city = value
    } else if (type === 'postal') {
      const entry = state.data.addresses.find((e) => { return e.id === id })
      entry.postal = value
    } else if (type === 'name') {
      state.data.name = value
    } else if (type === 'description') {
      state.data.description = value
    } else {
      const errorMessage = 'Can not update type "' + type + '"'
      throw errorMessage
    }
  },
  deleteEntry (state, { type, id }) {
    if (type === 'phonenumbers') {
      state.data.phonenumbers = state.data.phonenumbers.filter((e) => { return e.id !== id })
    } else if (type === 'addresses') {
      state.data.addresses = state.data.addresses.filter((e) => { return e.id !== id })
    } else {
      const errorMessage = 'Can not delete type "' + type + '"'
      throw errorMessage
    }
  },
  updateName (state, name) {
    state.data.name = name
  }
}

export const Profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
