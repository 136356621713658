import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'
const urlAvailable = baseUrl + 'username/isAvailable/'

const url = baseUrl + 'user/'

// initial state
const state = {
  status: '',
  errorMessage: ''
}

// getters
const getters = {
}

// actions
const actions = {
  post ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('request')
      user.creation_platform = 'dataforsyningen.dk'
      axios.post(url, user, { timeout: 60000 })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error', err)
          reject(err)
        })
    })
  },
  getAvailability ({ commit, dispatch }, value) {
    return new Promise((resolve, reject) => {
      axios.get(urlAvailable + value, { timeout: 60000 })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  request (state) {
    state.status = 'loading'
  },
  success (state) {
    state.status = 'success'
  },
  error (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'error'
  }
}

export const CreateUser = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
