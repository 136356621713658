import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { parseAssets } from '@/store/shared/ParseData'
import { mGetId } from '@/store/shared/Queries'
import { assetsGet } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const crud = new ElasticSearch(mGetId, assetsGet, parseAssets),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const NewsOverviewAssets = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
