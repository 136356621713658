import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { dataproductsSearch } from '@/store/shared/Paths'
import { mGetId } from '@/store/shared/Queries'
import { parseDataProduct } from '@/store/shared/ParseData'

// eslint-disable-next-line one-var
const parseData = (data) => {
    const state = []
    data.hits.hits.forEach(element => {
      state.push(parseDataProduct(element))
    })

    return state
  },
  crud = new ElasticSearch(mGetId, dataproductsSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const DatasetDataProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
