import axios from 'axios'

// TODO: use fetch instead of axios, implement progress and abortAPI
export class ElasticSearch {
  constructor (query, path, parseData) {
    this.state = {
      data: [],
      status: ''
    }

    this.getters = {
    }

    this.actions = {
      get ({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
          commit('request')
          axios.post(window.location.origin + '/' + path, query(data))
            .then((resp) => {
              commit('success', resp.data)
              resolve(resp)
            })
            .catch((err) => {
              commit('error', err)
              reject(err)
            })
        })
      },
      clear ({ commit, dispatch }) {
        commit('clear')
      }
    }

    this.mutations = {
      request (state) {
        state.status = 'loading'
      },
      success (state, data) {
        state.data = parseData(data)
        state.status = 'success'
      },
      error (state) {
        state.status = 'error'
      },
      clear (state) {
        state.data = []
      }
    }
  }
}
