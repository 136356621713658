import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'

const urlGet = baseUrl + 'newsletters/'
const urlPut = baseUrl + 'newsletter/'

// initial state
const state = {
  status: '',
  statusGet: '',
  data: [],
  errorMessage: ''
}

// getters
const getters = {
  data (state) { return state.data }
}

// actions
const actions = {
  get ({ commit, dispatch, rootState }) {
    return new Promise(function (resolve, reject) {
      commit('requestGet')
      axios.get(urlGet) // + rootState.SubUsers.selectedUser)
        .then(function (resp) {
          const obj = resp.data
          const array = []
          Object.keys(obj).forEach(function (key) {
            const objInner = obj[key].newsletters
            const arrayInner = []
            Object.keys(objInner).forEach(function (keyInner) {
              objInner[keyInner].id = keyInner
              arrayInner.push(objInner[keyInner])
            })
            array.push({
              id: Number(key),
              email: obj[key].email,
              newsletters: arrayInner
            })
          })
          commit('successGet', array)
          resolve(resp)
        })
        .catch(function (err) {
          commit('errorGet', err)
          reject(err)
        })
    })
  },
  put ({ commit, dispatch, rootState }, data) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.put(urlPut, data) // + rootState.SubUsers.selectedUser, data)
        .then(function (resp) {
          commit('success')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  request (state) {
    state.status = 'loading'
  },
  requestGet (state) {
    state.statusGet = 'loading'
  },
  success (state) {
    state.status = 'success'
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  successGet (state, data) {
    state.statusGet = 'success'
    state.data = data
  },
  error (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'error'
    setTimeout(function () { state.status = 'idle' }, 1000)
  },
  errorGet (state) {
    state.statusGet = 'error'
  }
}

export const Subscriptions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
