import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'

const url = baseUrl + 'usertypes'

// initial state
const state = {
  statusGet: '',
  data: []
}

// getters
const getters = {
  status (state) { return state.statusGet },
  userTypes (state) { return state.data }
}

const build = (parent, types) => Object.keys(types).map(key => {
  const element = { parent, id: key, name: types[key].name, subtypes: null }
  element.subtypes = build(element, types[key].subtypes)
  return element
})
// actions
const actions = {
  get ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('requestGet')
      axios.get(url, { timeout: 60000 })
        .then((resp) => {
          const data = build(null, resp.data)
          const compare = (a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          }
          data.sort(compare)
          commit('successGet', data)
          resolve(data)
        })
        .catch((err) => {
          commit('errorGet', err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  requestGet (state) {
    state.statusGet = 'loading'
  },
  successGet (state, data) {
    state.statusGet = 'success'
    state.data = data
  },
  errorGet (state) {
    state.statusGet = 'error'
  }
}

export const UserTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
