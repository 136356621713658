import { getDownloadUrl } from '@/HelperFunctions'
import axios from 'axios'

export const DownloadSelect = {
  namespaced: true,
  state: () => ({
    status: '',
    data: [],
    highlightSource: null,
    filePath: '',
    gridSelect: true
  }),

  getters: {
    getData (state) {
      return state.data
    },
    getSelected (state) {
      return state.selected
    }
  },

  mutations: {
    request (state) {
      state.status = 'loading'
    },
    success (state, { data, url, filePath }) {
      // if exists remove existing and add
      const found = state.data.find((d) => { return d.id === url })
      if (found) {
        const index = state.data.indexOf(found)
        if (index > -1) {
          state.data.splice(index, 1)
        }
      }

      const parsedData = data
      state.data.push({
        id: url,
        data: parsedData
      })
      if (state.highlightSource) {
        state.highlightSource.clear()
      }
      state.filePath = filePath
      state.status = 'success'
    },
    error (state) {
      state.status = 'error'
    },
    setGridSelect (state, value) {
      state.gridSelect = value
    },
    clear (state) {
      state.data = []
      if (state.highlightSource) {
        state.highlightSource.clear()
      }
    },
    removeFeatureFromSelect (state, feature) {
      state.highlightSource.removeFeature(feature)
    },
    addFeatureToSelect (state, feature) {
      state.highlightSource.addFeature(feature)
    },
    setHighlightSource (state, source) {
      state.highlightSource = source
    },
    setFileSize (state, data) {
      const feature = state.highlightSource.getFeatures().find(e => {
        return e.get('id') === data.id
      })
      if (feature) {
        feature.set('size', data.size)
        // remove and add the feature to trigger reactivity
        state.highlightSource.removeFeature(feature)
        state.highlightSource.addFeature(feature)
      }
    }
  },

  actions: {
    get ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('request')
        axios.get(data.url)
          .then((resp) => {
            commit('success', { data: resp.data, url: data.url, filePath: data.filePath })
            resolve(resp)
          })
          .catch((err) => {
            commit('error', err)
            reject(err)
          })
      })
    },
    setGridSelect ({ commit }, value) {
      commit('setGridSelect', value)
    },
    clear ({ commit }) {
      commit('clear')
    },
    toggleSelect ({ commit, state, dispatch }, feature) {
      if (!state.highlightSource) {
        return
      }
      const found = state.highlightSource.getFeatures().find(e => {
        return e.get('id') === feature.get('id')
      })
      if (found) {
        commit('removeFeatureFromSelect', found)
      } else {
        commit('addFeatureToSelect', feature)
        if (state.gridSelect) {
          dispatch('getFileSize', feature)
        }
      }
    },
    addFeaturesToSelect ({ commit, state, dispatch }, features) {
      features.forEach(feature => {
        const found = state.highlightSource.getFeatures().find(e => {
          return e.get('id') === feature.get('id')
        })
        if (!found) {
          commit('addFeatureToSelect', feature)
          if (state.gridSelect) {
            dispatch('getFileSize', feature)
          }
        }
      })
    },
    getFileSize ({ commit, state }, feature) {
      const filename = feature.get('filename') || feature.get('KOMKODE') || feature.get('REGIONKODE')
      const bearerToken = localStorage.getItem('user-token')
      const formatedToken = bearerToken.replace('Bearer ', '').match(/(\w*\.\w*\.)/g)[0]
      // need to create file names from kommode & regionkode. hopefully consistent naming?
      axios.head(getDownloadUrl(process.env.NODE_ENV) + state.filePath + '/' + filename + '?bearertoken=' + formatedToken)
        .then((resp) => {
          commit('setFileSize', {
            id: feature.get('id'),
            size: Number(resp.headers['content-length'])
          })
        })
    },
    setHighlightSource ({ commit }, source) {
      commit('setHighlightSource', source)
    }
  }
}
