import axios from 'axios'

const baseUrl = window.location.origin + '/user-api/'

const url = baseUrl + 'password/reset/'
const urlByEmail = baseUrl + 'password/reset-by-emailaddress/'
const urlConfirm = baseUrl + 'user/password'

// initial state
const state = {
  status: '',
  errorMessage: ''
}

// getters
const getters = {
}

// actions
const actions = {
  get ({ commit, dispatch }, username) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.get(url + username)
        .then(function (resp) {
          commit('success')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  getByEmail ({ commit, dispatch }, email) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.get(urlByEmail + email)
        .then(function (resp) {
          commit('success')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  put ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.put(url, data)
        .then(function (resp) {
          commit('success')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  post ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      commit('request')
      axios.post(urlConfirm, data)
        .then(function (resp) {
          commit('success')
          resolve(resp)
        })
        .catch(function (err) {
          commit('error', err)
          reject(err)
        })
    })
  },
  clear ({ commit, dispatch }) {
    commit('clear')
  }
}

// mutations
const mutations = {
  request (state) {
    state.status = 'loading'
  },
  success (state) {
    state.status = 'success'
  },
  error (state, err) {
    if (err.response.status === 500) {
      state.errorMessage = ''
    } else {
      state.errorMessage = err.response.data
    }
    state.status = 'error'
  },
  clear (state) {
    state.status = ''
  }
}

export const ResetPassword = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
