import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { footerSearch } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const query = () => {
    return {}
  },
  parseData = (data) => {
    const state = []
    data.hits.hits.forEach((e) => {
      const footer = {
        contactTitle: e._source.footer_contacttitle || '',
        name: e._source.footer_name || '',
        address: e._source.footer_address || '',
        city: e._source.footer_postalcode || '',
        phone: e._source.footer_phone || '',
        mail: e._source.footer_mail || '',
        ean: e._source.footer_ean || '',
        cvr: e._source.footer_cvr || '',
        followTitle: e._source.footer_followtitle || '',
        followLinks: e._source.footer_links.follow_links || [],
        visitTitle: e._source.footer_visittitle || '',
        visitLinks: e._source.footer_links.visit_links || [],
        ministryTitle: e._source.footer_ministrytitle || ''
      }
      state.push(footer)
    })
    return state
  },
  crud = new ElasticSearch(query, footerSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const Footer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
