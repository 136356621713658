import
/* webpackChunkName: "Vuex" */
/* webpackPreload: true */
Vuex from 'vuex'

import
/* webpackChunkName: "FooterStore" */
/* webpackPreload: true */
{ Footer } from '@/store/Footer'
import
/* webpackChunkName: "BasketStore" */
/* webpackPreload: true */
{ Basket } from '@/store/basket/Basket'
import
/* webpackChunkName: "BasketServicesStore" */
/* webpackPreload: true */
{ BasketServices } from '@/store/basket/BasketServices'
import { Home } from '@/store/home/Home'
import { HomeAssets } from '@/store/home/HomeAssets'
import { LinkBox } from '@/store/home/LinkBox'
import { LinkBoxAssets } from '@/store/home/LinkBoxAssets'
import { Datasets } from '@/store/datasets/Datasets'
import { DatasetsPage } from '@/store/datasets/DatasetsPage'
import { DatasetsPageAssets } from '@/store/datasets/DatasetsPageAssets'
import { DatasetsServices } from '@/store/datasets/DatasetsServices'
import { DatasetsAssets } from '@/store/datasets/DatasetsAssets'
import { DatasetsAttributes } from '@/store/datasets/DatasetsAttributes'
import { Labs } from '@/store/labs/Labs'
import { LabsAssets } from '@/store/labs/LabsAssets'
import { LabsPage } from '@/store/labs/LabsPage'
import { LabsPageAssets } from '@/store/labs/LabsPageAssets'
import { Apps } from '@/store/apps/Apps'
import { AppsAssets } from '@/store/apps/AppsAssets'
import { AppsPage } from '@/store/apps/AppsPage'
import { AppsPageAssets } from '@/store/apps/AppsPageAssets'
import { Tags } from '@/store/tags/Tags'

import
/* webpackChunkName: "CapabilityStore" */
/* webpackPreload: true */
{ Capability } from '@/store/capability/Capability'

import User from '@/store/user'
import Cases from '@/store/cases'
import Case from '@/store/case'
import NewsOverview from '@/store/newsOverview'
import News from '@/store/news'
import Dataset from '@/store/dataset'
import Lab from '@/store/lab'
import Help from '@/store/help'
import About from '@/store/about'
import Terms from '@/store/terms'
import Cookies from '@/store/cookies'
import Privacy from '@/store/privacy'
import Monitor from '@/store/monitor'

import { Dialog } from '@/store/Dialog'
import { Databox } from '@/store/basket/Databox'
import { DownloadSelect } from '@/store/basket/DownloadSelect'

// Filters
import { DatasetsFilters } from '@/store/datasets/DatasetsFilters'

export default Vuex.createStore({
  modules: {
    Home,
    HomeAssets,
    LinkBox,
    LinkBoxAssets,
    Footer,
    Basket,
    BasketServices,
    Datasets,
    DatasetsPage,
    DatasetsPageAssets,
    DatasetsServices,
    DatasetsAssets,
    DatasetsAttributes,
    Capability,
    Dialog,
    Databox,
    DownloadSelect,
    Labs,
    LabsAssets,
    LabsPage,
    LabsPageAssets,
    Apps,
    AppsAssets,
    AppsPage,
    AppsPageAssets,
    Tags,

    ...User,
    ...Cases,
    ...Case,
    ...NewsOverview,
    ...News,
    ...Dataset,
    ...Lab,
    ...Help,
    ...About,
    ...Cookies,
    ...Terms,
    ...Privacy,
    ...Monitor,

    DatasetsFilters
  }
})
