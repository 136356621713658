import axios from 'axios'
import { userdefinedPrefix } from '@/Constants'

const downloadUrl = window.location.origin + '/brugerdownloads/custom-order'

const state = () => ({
  // items: localStorage.getItem('databox') ? JSON.parse(localStorage.getItem('databox')) : []
  items: [],
  addDownloadItem: null, // { dataset, datasetAssets, index }
  refreshStatus: ''
})

const getters = {
  getItem (state, id) {
    return state.items.find(item => item.id === id)
  },
  getAddDownloadItem (state) {
    return state.addDownloadItem
  }
}

const actions = {
  add ({ commit, state }, item) {
    const found = state.items.find(({ id, attributeType }) => id === item.id && attributeType === item.attributeType)
    if (!found) {
      commit('addItem', item)
    }
  },
  remove ({ commit }, item) {
    commit('removeItem', item)
  },
  setAddDownloadItem ({ commit, dispatch }, item) {
    if (item) {
      dispatch('DownloadSelect/setGridSelect', item.dataset.downloads[item.index].type === 'map', { root: true })
      commit('setAddDownloadItem', item)
    }
  },
  clearAddDownloadItem ({ commit }) {
    commit('clearAddDownloadItem')
  },
  refreshItems ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.refreshState === 'loading') {
        resolve()
      }
      commit('refresh')
      const incompleteOrders = state.items.filter(item => {
        return item.type === 'fme' && !item.path
      })
      if (incompleteOrders.length === 0) {
        return
      }
      axios.get(downloadUrl)
        .then((resp) => {
          commit('refreshSuccess', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          commit('refreshError', err)
          reject(err)
        })
    })
  }
}

const mutations = {
  addItem (state, item) {
    state.items.unshift(item)
  },
  removeItem (state, item) {
    state.items = state.items.filter(({ id, attributeType }) => !(id === item.id && attributeType === item.attributeType))
    // localStorage.setItem('databox', JSON.stringify(state.items))
  },
  setAddDownloadItem (state, item) {
    state.addDownloadItem = item
  },
  clearAddDownloadItem (state) {
    state.addDownloadItem = null
  },
  refresh (state) {
    state.refreshState = 'loading'
  },
  refreshSuccess (state, data) {
    const incompleteOrders = state.items.filter(item => {
      return item.type === 'fme' && !item.path
    })
    incompleteOrders.forEach(order => {
      const updatedOrder = data.find(uOrder => {
        return order.id === userdefinedPrefix + uOrder.orderId
      })
      if (updatedOrder) {
        order.path = updatedOrder.filePath
        order.files[0].filename = updatedOrder.files[0].filename
        order.files[0].size = updatedOrder.files[0].fileSize
        order.files[0].url = updatedOrder.files[0].fileUrl
      }
    })
    state.refreshState = 'success'
  },
  refreshError (state, err) {
    state.refreshState = 'error'
  }
}

export const Databox = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
