import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { frontpageSearch } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const query = () => {
    // eslint-disable-next-line multiline-ternary
    return (process.env.VUE_APP_SHOW_UNPUBLISHED === 'true') ? {
      query: {
        match: {
          page_type: 'WebAppspage'
        }
      }
    } : {
      query: {
        bool: {
          must: [
            {
              match: {
                o_published: true
              }
            }, {
              match: {
                page_type: 'WebAppspage'
              }
            }
          ]
        }
      }
    }
  },
  parseData = (data) => {
    const state = []
    for (let i = data.hits.hits.length - 1, c = 0; i > -1; --i, ++c) {
      const curItem = data.hits.hits[i]._source
      state[c] = {
        title: curItem.frontpage_title || '',
        summary: curItem.frontpage_summary || '',
        image: curItem.frontpage_image || '',
        gradient: curItem.image_gradient || '',
        boxGradient: curItem.box_gradient || '',
        justify: curItem.image_justify || ''
      }
    }
    return state
  },
  crud = new ElasticSearch(query, frontpageSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const AppsPage = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
