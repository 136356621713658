import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { attributesSearch } from '@/store/shared/Paths'
import { webserviceFilterType, downloadFilterType, coordinateFilterType } from '@/Constants'

// eslint-disable-next-line one-var
const query = () => {
    return {
      size: 9999,
      query: {
        bool: {
          should: [
            {
              match: {
                c_name: coordinateFilterType
              }
            },
            {
              match: {
                c_name: downloadFilterType
              }
            },
            {
              match: {
                c_name: webserviceFilterType
              }
            }
          ],
          minimum_should_match: 1
        }
      }
    }
  },
  parseCoordinateSystem = (data) => {
    // eslint-disable-next-line one-var
    const exist = !!data._source,
      source = (exist) ? data._source : ''
    // eslint-disable-next-line multiline-ternary
    return (exist) ? {
      id: Number(source.o_id),
      title: source.coord_name,
      description: source.coord_description,
      epsg: source.coord_epsg,
      type: coordinateFilterType,
      // Temporarily use official name as priority
      officialName: Number(source.coord_officialname) || 999,
      readMore: source.coord_readmore,
      backgroundColor: source.coord_backgroundcolor,
      color: source.coord_color,
      list: []
    } : {
      id: 0,
      title: '',
      type: coordinateFilterType,
      color: '',
      list: []
    }
  },
  parseFormat = (data) => {
    // eslint-disable-next-line one-var
    const exist = !!data._source,
      source = (exist) ? data._source : ''
    // eslint-disable-next-line multiline-ternary
    return (exist) ? {
      id: Number(source.o_id),
      title: source.format_name,
      type: downloadFilterType,
      backgroundColor: source.format_backgroundcolor,
      color: source.format_color,
      priority: source.format_priority,
      mimetype: source.format_mimetype,
      extension: source.format_fileextension,
      list: []
    } : {
      id: 0,
      title: '',
      type: downloadFilterType,
      color: '',
      priority: 0,
      mimetype: '',
      extension: '',
      list: []
    }
  },
  parseServiceType = (data) => {
    // eslint-disable-next-line one-var
    const exist = !!data._source,
      source = (exist) ? data._source : ''
    // eslint-disable-next-line multiline-ternary
    return (exist) ? {
      id: Number(source.o_id),
      title: source.servicetype_name,
      type: webserviceFilterType,
      backgroundColor: source.servicetype_backgroundcolor,
      color: source.servicetype_color,
      priority: source.servicetype_priority,
      list: []
    } : {
      id: 0,
      title: '',
      type: webserviceFilterType,
      color: '',
      priority: 0,
      list: []
    }
  },
  parseData = (data) => {
    const state = [
      { // coordinateSystems
        title: coordinateFilterType,
        list: []
      }, { // formats
        title: downloadFilterType,
        list: []
      }, { // serviceType
        title: webserviceFilterType,
        list: []
      }
    ]
    for (let i = data.hits.hits.length - 1, x = 0, y = 0, z = 0; i > -1; --i) {
      // eslint-disable-next-line one-var
      const curItem = data.hits.hits[i],
        cname = curItem._source.c_name;
      // eslint-disable-next-line no-unused-expressions
      (cname === webserviceFilterType)
        ? ((state[2].list[x] = parseServiceType(curItem)) && (++x))
        : (cname === downloadFilterType)
            ? ((state[1].list[y] = parseFormat(curItem)) && (++y))
            : (cname === coordinateFilterType)
                ? ((state[0].list[z] = parseCoordinateSystem(curItem)) && (++z))
                : null
      /*
      switch (curItem._source.c_name) {
        case attributeCategories.coordinateSystem.name:
          state[0].list[c] = parseCoordinateSystem(curItem)
          break
        case attributeCategories.format.name:
          state[1].list[c] = parseFormat(curItem)
          break
        case attributeCategories.serviceType.name:
          state[2].list[c] = parseServiceType(curItem)
          break
        default:
      }
      */
    }
    /*
    state.push(formats)
    state.push(coordinateSystems)
    state.push(serviceType)
    */
    /*
    data.hits.hits.forEach((e) => {
      if (e._source.c_name === attributeCategories.coordinateSystem.name) {
        coordinateSystems.list.push(parseCoordinateSystem(e))
      } else if (e._source.c_name === attributeCategories.format.name) {
        formats.list.push(parseFormat(e))
      } else if (e._source.c_name === attributeCategories.serviceType.name) {
        serviceType.list.push(parseServiceType(e))
      }
    })
    */

    state.forEach(type => {
      type.list.sort((a, b) => {
        // eslint-disable-next-line one-var
        const nameA = a.priority,
          nameB = b.priority
        if (nameA > nameB) {
          return -1
        } else if (nameA < nameB) {
          return 1
        }
        return 0
      })
    })
    return state
  },
  crud = new ElasticSearch(query, attributesSearch, parseData),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const DatasetsAttributes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
