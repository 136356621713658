export const tagsGet = 'es/tags/_search'
export const tagsSearch = 'es/tags/_search'

export const assetsGet = 'es/assets/_search'
export const assetsSearch = 'es/assets/_search'

export const linkboxGet = 'es/linkbox/_search'
export const linkboxSearch = 'es/linkbox/_search'

export const footerGet = 'es/footer/_search'
export const footerSearch = 'es/footer/_search'

export const frontpageGet = 'es/frontpage/_search'
export const frontpageSearch = 'es/frontpage/_search'

export const attributesGet = 'es/attributes/_search'
export const attributesSearch = 'es/attributes/_search'

export const servicesGet = 'es/services/_search'
export const servicesSearch = 'es/services/_search'

export const featuresGet = 'es/features/_search'
export const featuresSearch = 'es/features/_search'

export const registersGet = 'es/registers/_search'
export const registersSearch = 'es/registers/_search'

export const dataproductsGet = 'es/dataproducts/_search'
export const dataproductsSearch = 'es/dataproducts/_search'

export const websitesGet = 'es/websites/_search'
export const websitesSearch = 'es/websites/_search'
export const appsGet = 'es/websites/_search'
export const appsSearch = 'es/websites/_search'

export const casesGet = 'es/cases/_search'
export const casesSearch = 'es/cases/_search'

export const productsGet = 'es/products/_search'
export const productsSearch = 'es/products/_search'

export const labsGet = 'es/labs/_search'
export const labsSearch = 'es/labs/_search'

export const municipalitySearch = 'es/kommuner/_search'

export const regionsSearch = 'es/regioner/_search'

export const globalSearch = 'es/cases,products/_search'
export const realGlobalSearch = 'es/_all/_search'

export const newsGet = 'es/news/_search'
export const newsSearch = 'es/news/_search'

export const uptimeTablesSearch = 'es/uptimetables/_search'

export const faqSearch = 'es/faq/_search'
