import
/* webpackChunkName: "Vue" */
/* webpackPreload: true */
{ createApp, defineAsyncComponent } from 'vue'

import { DSNavScrollable, DSLogo } from '@dataforsyningen/designsystem'
import { GSearchUI } from '@dataforsyningen/gsearch-ui'

import
/* webpackChunkName: "store" */
/* webpackPreload: true */
store from '@/store'
import
/* webpackChunkName: "router" */
/* webpackPreload: true */
router from '@/router'

import
/* webpackChunkName: "Detect" */
/* webpackPreload: true */
{ isIE, isConsole, isWearable, isSmartTV } from 'mobile-device-detect'

import
/* webpackPreload: true */
colors from '@/plugins/colors'

import
/* webpackPreload: true */
App from './App.vue'

import
/* webpackChunkName: "axios" */
/* webpackPreload: true */
axios from 'axios'

if (!(isIE || isConsole || isWearable || isSmartTV)) {
  require(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "EasterEgg" */
    '@/assets/js/easterEgg'
  )
}

import(
  /* webpackChunkName: "sanitize" */
  /* webpackPreload: true */
  'sanitize.css'
)
import(
  /* webpackChunkName: "sanitize_forms" */
  /* webpackPreload: true */
  'sanitize.css/forms.css'
)
import(
  /* webpackChunkName: "sanitize_assets" */
  /* webpackPreload: true */
  'sanitize.css/assets.css'
)
import(
  /* webpackChunkName: "sanitize_reduce-motion" */
  /* webpackPreload: true */
  'sanitize.css/reduce-motion.css'
)

import(
  /* webpackChunkName: "classes" */
  /* webpackPreload: true */
  './assets/css/classes.styl'
)
import(
  /* webpackChunkName: "ol" */
  /* webpackPreload: true */
  './assets/css/ol.styl'
)
import(
  /* webpackChunkName: "grid" */
  /* webpackPreload: true */
  './assets/css/grid.css'
)
import(
  /* webpackChunkName: "designsystem" */
  /* webpackPreload: true */
  '@dataforsyningen/designsystem/index.scss'
)

import(
  /* webpackChunkName: "animate" */
  /* webpackPreload: true */
  'animate.css/source/_base.css'
)
import(
  /* webpackMode: "lazy" */
  /* webpackChunkName: "animate_fadeIn" */
  /* webpackPreload: true */
  'animate.css/source/fading_entrances/fadeIn.css'
)
import(
  /* webpackMode: "lazy" */
  /* webpackChunkName: "animate_fadeOut" */
  /* webpackPreload: true */
  'animate.css/source/fading_exits/fadeOut.css'
)
import(
  /* webpackMode: "lazy" */
  /* webpackChunkName: "animate_bounceInUp" */
  /* webpackPreload: true */
  'animate.css/source/bouncing_entrances/bounceInUp.css'
)

if (!customElements.get('ds-nav-scrollable')) {
  customElements.define('ds-nav-scrollable', DSNavScrollable)
}
if (!customElements.get('g-search')) {
  customElements.define('g-search', GSearchUI)
}
if (!customElements.get('ds-logo')) {
  customElements.define('ds-logo', DSLogo)
}

const app = createApp(App)
const isNotProd = (process.env.NODE_ENV !== 'production')
app.config.globalProperties.append = (path, pathToAppend) => path + (path.endsWith('/') ? '' : '/') + pathToAppend
app.config.performance = isNotProd
app.config.compilerOptions.comments = isNotProd
app.config.devtools = isNotProd
app.config.compilerOptions.delimiters = ['{{', '}}']
app.config.compilerOptions.whitespace = 'condense'

const chaoticEvil = window.matchMedia('(prefers-color-scheme: dark)').matches
const lawfulGood = window.matchMedia('(prefers-color-scheme: light)').matches
const trueNeutral = window.matchMedia('(prefers-color-scheme: no-preference)').matches
app.config.globalProperties.preferDark = !(lawfulGood || trueNeutral) || (chaoticEvil)

app
  .use(colors)
  .component(
    'Icon', defineAsyncComponent(() => import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "IconWrapper" */
      /* webpackPreload: true */
      '@/components/shared/Icon'
    ))
  )
  .use(router)
  .use(store)

// configure axios
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common.Pragma = 'no-cache'

// add Authorization header when calling burgerstyring or download APIs: '/brugerdownloads/' '/user-api/'
axios.interceptors.request.use(function (config) {
  if (config.url.includes(window.location.origin + '/brugerdownloads/') ||
    config.url.includes(window.location.origin + '/user-api/')) {
    config.headers.Authorization = localStorage.getItem('user-token')
  }
  return config
})

// token header der giver Rune Emde adgang til Burgerstyrings API ude fra:
// axios.defaults.headers.common.token = '7aa6e08b3a5e6bbf1fa7abdd54489c7b'

window.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line no-new
  app.directive('click-outside', {
    bind: (el, binding, vnode) => {
      el.clickOutsideEvent = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent, { capture: true, once: false, passive: true })
    },
    unbind: (el) => {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })

  app.mount('#app')
}, { capture: true, once: true, passive: true })
