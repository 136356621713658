import { useStore } from 'vuex'

export const useAuth = () => {
  const store = useStore()

  const IsLoggedIn = () => { return store.getters['Auth/isAuthenticated'] }
  const status = () => { return store.getters['Auth/authStatus'] }

  const isAuthenticated = () => {
    // will try to check for authenticated status 10 times
    const waitForAuthAndResolve = (resolve, reject, index) => {
      if (index++ > 9) {
        reject(new Error('Authentication never completed'))
        return
      }
      if (store.getters['Auth/authStatus'] === 'loading') {
        setTimeout(() => { waitForAuthAndResolve(resolve, reject, index) }, 5000)
      } else {
        resolve(store.getters['Auth/isAuthenticated'])
      }
    }

    return new Promise((resolve, reject) => {
      waitForAuthAndResolve(resolve, reject, 0)
    })
  }

  const Login = ({ username, password }) => {
    if (username && password) {
      return store.dispatch('Auth/request', { username, password })
    }
  }
  const Refresh = () => store.dispatch('Auth/refresh').catch(() => { })
  const logout = () => store.dispatch('Auth/logOut')
  const clearOldToken = () => store.dispatch('Auth/clearOldToken')

  return {
    IsLoggedIn,
    isAuthenticated,
    status,
    Login,
    Refresh,
    logout,
    clearOldToken
  }
}
