import { ElasticSearch } from '@/store/shared/ElasticSearch'
import { getAll } from '@/store/shared/Queries'
import { servicesGet } from '@/store/shared/Paths'

// eslint-disable-next-line one-var
const parseServices = (data) => {
    // eslint-disable-next-line one-var
    const state = [],
      array = data.hits ? data.hits.hits : data.docs
    for (let i = array.length - 1, c = 0; i > -1; --i) {
      const source = array[i]._source
      state[c] = {
        id: source.o_id,
        title: source.service_title,
        type: source.service_servicetype,
        name: source.service_name,
        link: source.service_link,
        typeName: source.service_servicetypename
      }
      ++c
    }
    return state
  },
  crud = new ElasticSearch(getAll, servicesGet, parseServices),
  state = crud.state,
  getters = crud.getters,
  actions = crud.actions,
  mutations = crud.mutations

export const DatasetsServices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
