const state = {
  data: [
    {
      image: -1,
      title: 'Få hjælp',
      summary: 'Har du brug for hjælp er du velkommen til at kontakte vores support. Du kan også finde vejledninger og svar på de oftest stillede spørgsmål i vores FAQ.'
    }
  ]
}

export const HelpPage = {
  namespaced: true,
  state
}
