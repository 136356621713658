<template>
  <router-view />
</template>

<script>
import { onBeforeMount } from 'vue'
import { useAuth } from '@/use/auth'
import { useProfile } from '@/use/profile'

export default {
  name: 'App',
  setup () {
    const auth = useAuth()
    const profile = useProfile()

    onBeforeMount(async () => {
      const userToken = localStorage.getItem('user-token')
      const refreshToken = localStorage.getItem('refresh-token')
      if (userToken) {
        profile.getProfile().catch((error) => {
          if (error.response.status !== 200) {
            auth.clearOldToken()
            if (refreshToken) {
              auth.Refresh().then((response) => {
                if (response.status === 200) {
                  profile.getProfile().catch(() => {})
                }
              })
            }
          }
        })
      }
    })
  }
}
</script>
